import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { If, Text } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/Badges';

import { Price } from '@/src/infrastructure/interfaces/IResponses';
import { DownGradeStatus } from '@/src/modules/MyPlanModule/screens/BillingAndConsumption/components';
import { ModalComparisonOfPlans } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/ModalComparisonOfPlans';
import { Plans } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/Plans';

import { atomContractDetail } from '@/modules/MyPlanModule/atoms/BillingAndConsumption';
import { RadioSwitch } from '@/modules/MyPlanModule/components';
import { useDisabledPlanButton } from '@/modules/MyPlanModule/hooks/useDisabledPlanButton';
import { useDowngradeStatus } from '@/modules/MyPlanModule/hooks/useDowngradeStatus';
import { useSelectedPlanData } from '@/modules/MyPlanModule/hooks/useSelectedPlanData';

export interface Frequency {
  value: keyof Price;
  label: string;
  priceSuffix: string;
}

const StepOneManagePlan = () => {
  const { t } = useTranslation();
  const [modalComparisonIsOpen, setModalComparisonIsOpen] = useState(false);
  const { downgradeIsPending, downgradeStatus } = useDowngradeStatus();
  const { handleSelectPlan, frequency, handleFrequency, frequencyOptions } = useSelectedPlanData();
  const { hasNewPlans } = useDisabledPlanButton();
  const contractDetail = useAtomValue(atomContractDetail);

  const openModalComparison = useCallback(() => {
    setModalComparisonIsOpen(true);
  }, []);

  useEffect(() => {
    if (hasNewPlans) {
      contractDetail?.billingPeriod === 'annually' && handleFrequency(frequencyOptions[1]);
    } else {
      handleFrequency(frequencyOptions[0]);
    }
  }, [contractDetail?.billingPeriod, frequencyOptions, handleFrequency, hasNewPlans]);

  return (
    <div className="relative w-full">
      <div className="flex flex-col gap-4">
        <div className="relative ml-28 flex items-center justify-center gap-4">
          <RadioSwitch
            options={frequencyOptions}
            size="md"
            value={frequency}
            onChange={handleFrequency}
          />
          <div className="z-2 relative -left-8 h-6 overflow-hidden rounded-lg">
            <BadgeText text={t('MANAGE_PLAN.STEP_ONE.discount')} variantColor="success" />
          </div>
        </div>
        <If condition={downgradeIsPending}>
          <div className="mx-auto w-[380px]">
            <DownGradeStatus
              isAlert
              isCustomization={
                downgradeStatus?.length
                  ? downgradeStatus[0].period !== contractDetail?.billingPeriod
                  : false
              }
            />
          </div>
        </If>
        <Plans
          downgradeIsPending={downgradeIsPending}
          frequency={frequency}
          onSelectedPlan={handleSelectPlan}
        />
        <Text
          alignment="center"
          className="my-8"
          color="primary"
          fontWeight="medium"
          id="open-plans-modal"
          onClick={openModalComparison}
        >
          {t('MANAGE_PLAN.viewAllDetailPlans')}
        </Text>
      </div>
      <ModalComparisonOfPlans
        frequency={frequency}
        isOpen={modalComparisonIsOpen}
        onClose={setModalComparisonIsOpen}
        onSelectedPlan={handleSelectPlan}
      />
    </div>
  );
};

export default StepOneManagePlan;
