import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { If, Text } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/Badges';

import { numberToCurrency } from '@/src/utils/currency';

type TotalPriceComponentProps = {
  proratedTotalPrice?: number;
  monthlyTotal: number;
  annuallyTotal: number;
  monthSuffixText: string;
  isAnnuallySelected: boolean;
  isFreemiumPlan: boolean;
  prorationAmount?: number;
  prorationAmountIsLoading?: boolean;
};

const TotalPrice = ({
  proratedTotalPrice,
  monthlyTotal,
  annuallyTotal,
  monthSuffixText,
  isAnnuallySelected,
  isFreemiumPlan,
  prorationAmount,
  prorationAmountIsLoading,
}: TotalPriceComponentProps) => {
  const { t } = useTranslation();

  const useProratedPrice = useCallback(
    (isAnnuallySelected: boolean, proratedPrice?: number): boolean => {
      return !isAnnuallySelected && !!proratedPrice;
    },
    []
  );

  const useProratedPriceVar = useProratedPrice(isAnnuallySelected, proratedTotalPrice);
  const monthlyBoldPrice = useProratedPriceVar ? (proratedTotalPrice as number) : monthlyTotal;
  return (
    <div className="flex justify-between gap-2">
      <div className="h-[26px]">
        <If condition={isAnnuallySelected && !isFreemiumPlan}>
          <BadgeText
            text={`${t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.payAnnually')} -20%`}
            variantColor="success"
          />
        </If>
      </div>
      <div className="flex flex-col">
        <Text alignment="right" variant="title-1">
          USD {numberToCurrency(monthlyBoldPrice)}
          {useProratedPriceVar ? undefined : monthSuffixText}
        </Text>
        <If condition={useProratedPriceVar}>
          <Text alignment="right" variant="text">
            {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.nextMonthPrice')} USD{' '}
            {numberToCurrency(monthlyTotal)}
            {monthSuffixText}
          </Text>
        </If>
        <If condition={isAnnuallySelected && !isFreemiumPlan}>
          <Text alignment="right" fontWeight="medium">
            {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.annuallyTotal', {
              amount: numberToCurrency(annuallyTotal),
            })}
          </Text>
        </If>
        <If condition={prorationAmountIsLoading}>
          <div className="h-3 w-full animate-pulse rounded-full bg-gray-200"></div>
        </If>
        <If condition={prorationAmount !== undefined}>
          <Text alignment="right" fontWeight="medium" variant="text">
            {t('TotalPriceComponent.differenceToPay')} USD {numberToCurrency(prorationAmount ?? 0)}
          </Text>
        </If>
      </div>
    </div>
  );
};

export default memo(TotalPrice);
