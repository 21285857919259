import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/solid';
import { useAtomValue } from 'jotai';
import { memo, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  ButtonWithDropdown,
  Card,
  CardSection,
  IconSvg,
  If,
  Text,
  toast,
} from '@/lib/v2/components';
import { DropdownItemProps } from '@/lib/v2/components/Dropdown';

import { ROLES } from '@/src/application/constants';
import { useStateUserContext } from '@/src/application/context/UserContext';
import {
  CreateCheckoutBody,
  NewBillingPeriod,
} from '@/src/infrastructure/interfaces/IRequestParams.interface';
import { PlanType } from '@/src/infrastructure/interfaces/IResponses';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { numberToCurrency } from '@/src/utils/currency';

import { DownGradeStatus } from './DownGradeStatus';
import ModalInformation from './ModalInformation';

import {
  atomBillingInformation,
  atomContractDetail,
} from '@/modules/MyPlanModule/atoms/BillingAndConsumption';
import { BILLING_FORM_DATA_KEY, MY_PLAN_PATHS } from '@/modules/MyPlanModule/constants';
import { useBillingPortal } from '@/modules/MyPlanModule/hooks/useBillingPortal';
import { useDowngradeStatus } from '@/modules/MyPlanModule/hooks/useDowngradeStatus';
import { useManagePlanSecurity } from '@/modules/MyPlanModule/hooks/useManagePlanSecurity';

interface HeaderMyPlanProps {
  contractId?: number;
  businessName?: string;
  isLoading?: boolean;
}

const HeaderMyPlan = ({ contractId, businessName, isLoading }: HeaderMyPlanProps) => {
  const service = useService();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { paymentInfo } = useStateUserContext();
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [moreInformationIsOpen, setMoreInformationIsOpen] = useState(false);
  const { downgradeIsPending, downgradeStatus } = useDowngradeStatus();
  const contractDetail = useAtomValue(atomContractDetail);
  const billingInformation = useAtomValue(atomBillingInformation);
  const { userData } = useStateUserContext();

  const { canAddToAutoDebit, billingInformationIsLoading, jwtToken, isFreemiumClient, isOldPlan } =
    useManagePlanSecurity();

  const hasRoleAdmin =
    ROLES.SUPER_ADMIN_ADMIN === userData.role || ROLES.AGENCY_ADMIN === userData.role;

  const { handleRedirectBillingPortal, isLoading: billingPortalIsLoading } = useBillingPortal();

  const handleClickMoreInformation = useCallback(() => {
    setMoreInformationIsOpen(true);
  }, []);

  const handleDebit = useCallback(async () => {
    setIsLoadingCheckout(true);
    const mapPlanNameByPlanId = {
      Starter: 'plan-starter',
      Advance: 'plan-advance',
    };

    const planId = (contractDetail?.planName as 'Starter' | 'Advance') ?? 'Starter';
    const body: CreateCheckoutBody = {
      mode: 'subscription',
      billingData: {
        businessName: billingInformation?.businessName ?? '',
        fantasyName: billingInformation?.fantasyName ?? '',
        rut: billingInformation?.rut ?? '',
        address: billingInformation?.address ?? '',
        city: billingInformation?.city ?? '',
        country: Number(billingInformation?.country),
        billingContact: billingInformation?.billingContact ?? '',
        billingEmail: billingInformation?.billingEmail ?? '',
      },
      planId: mapPlanNameByPlanId[planId],
      planName: contractDetail?.planName as PlanType,
      embedded: false,
      period: contractDetail?.billingPeriod as NewBillingPeriod,
      volume: {
        email: contractDetail?.planDetail.email ?? 10000,
        onsite: contractDetail?.planDetail.onsite ?? 10000,
        sms: contractDetail?.planDetail.sms ?? 0,
      },
    };
    const { url, errorType } = await service.createCheckoutSession(body);
    setIsLoadingCheckout(false);
    if (errorType) {
      //toast
      toast({
        id: 'autoDebitError',
        title: t('MY_PLAN.BILLING.debitAutoNotification.error.title'),
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        body: t(`MY_PLAN.BILLING.debitAutoNotification.error.body.${errorType}`),
        variant: 'error',
      });
      return;
    }

    localStorage.setItem(BILLING_FORM_DATA_KEY, JSON.stringify(body));
    window.open(url, '_self');
  }, [
    billingInformation?.address,
    billingInformation?.billingContact,
    billingInformation?.billingEmail,
    billingInformation?.businessName,
    billingInformation?.city,
    billingInformation?.country,
    billingInformation?.fantasyName,
    billingInformation?.rut,
    contractDetail?.billingPeriod,
    contractDetail?.planDetail.email,
    contractDetail?.planDetail.onsite,
    contractDetail?.planDetail.sms,
    contractDetail?.planName,
    service,
    t,
  ]);

  const handleClickManagePlan = useCallback(() => {
    navigate(`../${MY_PLAN_PATHS.MANAGE_PLAN}`);
  }, [navigate]);

  const dropdownItems = useMemo<DropdownItemProps[]>(() => {
    return [
      {
        id: 'billing-portal-button',
        label: t('MY_PLAN.BILLING.PLAN_DETAIL.portal'),
        value: 'billing-portal-button',
        onClick: handleRedirectBillingPortal,
      },
      {
        id: 'cancel-subscription-button',
        label: t('MY_PLAN.BILLING.cancelContract'),
        value: 'cancel-subscription-button',
        onClick: () => {
          window.open(`https://php.embluemail.com/unsubscribe/?token=${jwtToken}`, '_blank');
        },
      },
    ];
  }, [handleRedirectBillingPortal, jwtToken, t]);

  return (
    <>
      <Card>
        <CardSection paddingX="medium">
          <div className="flex items-center justify-between gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                {!isLoading ? (
                  <Text fontWeight="medium" variant="sub-headline">
                    {businessName}
                  </Text>
                ) : (
                  <div className="my-1 h-4 w-32 animate-pulse rounded-full bg-gray-200"></div>
                )}
                <If condition={paymentInfo && paymentInfo?.amount === 0}>
                  <div className="flex items-center gap-1">
                    <IconSvg fillColor="success" svgComponent={<CheckCircleIcon />} />
                    <Text isItalic variant="text">
                      {t('MY_PLAN.BILLING.noDebts')}
                    </Text>
                  </div>
                </If>
                <If
                  condition={
                    paymentInfo && paymentInfo?.amount > 0 && !paymentInfo?.hasStripeSubscription
                  }
                >
                  <div className="flex items-center gap-1">
                    <IconSvg fillColor="passive" svgComponent={<ExclamationIcon />} />
                    <Text isItalic variant="text">
                      <Trans
                        components={{
                          l: (
                            <Text
                              link
                              color="primary"
                              fontWeight="medium"
                              href={paymentInfo?.payUrl}
                              isItalic={false}
                              target="_blank"
                              variant="text"
                            />
                          ),
                        }}
                        i18nKey={'MY_PLAN.BILLING.debitAlert'}
                        values={{
                          amount: `${paymentInfo?.currency} ${numberToCurrency(
                            paymentInfo?.amount ?? 0
                          )}`,
                        }}
                      ></Trans>
                    </Text>
                  </div>
                </If>
                <If
                  condition={
                    paymentInfo?.hasStripeSubscription &&
                    (paymentInfo?.isSuspended || paymentInfo.isBlocked)
                  }
                >
                  <div className="flex items-center gap-1">
                    <IconSvg fillColor="passive" svgComponent={<ExclamationIcon />} />
                    <Text isItalic variant="text">
                      <Trans
                        components={{
                          l: (
                            <Text
                              link
                              color="primary"
                              fontWeight="medium"
                              isItalic={false}
                              variant="text"
                              onClick={
                                !billingPortalIsLoading ? handleRedirectBillingPortal : undefined
                              }
                            />
                          ),
                        }}
                        i18nKey={'HeaderMyPlan.alertSuspendedStripe'}
                      ></Trans>
                    </Text>
                  </div>
                </If>
              </div>
              <If condition={!isLoading}>
                <div className="flex gap-4">
                  <Text fontWeight="medium" variant="text">
                    {t('MY_PLAN.BILLING.contractName', {
                      contractId,
                      company: billingInformation?.fantasyName,
                    })}
                  </Text>
                  <If
                    condition={
                      billingInformation?.hasStripeSubscription ||
                      isFreemiumClient ||
                      canAddToAutoDebit
                    }
                  >
                    <Text
                      link
                      color="primary"
                      id="edit-my-account"
                      variant="text"
                      onClick={handleClickMoreInformation}
                    >
                      {hasRoleAdmin
                        ? t('MY_PLAN.BILLING.editAccount')
                        : t('MY_PLAN.BILLING.moreInfo')}
                    </Text>
                  </If>
                </div>
              </If>
              <If condition={isLoading}>
                <div className="my-1 h-[13px] w-40 animate-pulse rounded-full bg-gray-200"></div>
              </If>
            </div>
            <div className="flex items-center gap-8">
              <If condition={downgradeIsPending}>
                <DownGradeStatus
                  isCustomization={
                    downgradeStatus?.length
                      ? downgradeStatus[0].period !== contractDetail?.billingPeriod
                      : false
                  }
                />
              </If>
              <div className="flex flex-none gap-4">
                <If condition={billingInformationIsLoading}>
                  <div className="my-1 h-[38px] w-32 animate-pulse rounded-md bg-gray-200"></div>
                </If>
                <If condition={canAddToAutoDebit}>
                  <Button isLoading={isLoadingCheckout} onClick={handleDebit}>
                    {t('MY_PLAN.BILLING.debitAuto')}
                  </Button>
                </If>
                <If condition={!billingInformationIsLoading}>
                  <If condition={isOldPlan && !billingInformation?.hasStripeSubscription}>
                    <Button outline secondary standard onClick={handleClickMoreInformation}>
                      {hasRoleAdmin
                        ? t('MY_PLAN.BILLING.editAccount')
                        : t('MY_PLAN.BILLING.moreInfo')}
                    </Button>
                  </If>
                  <If condition={hasRoleAdmin}>
                    <If condition={!canAddToAutoDebit && isFreemiumClient}>
                      <Button primary id="manage-plan" onClick={handleClickManagePlan}>
                        {t('MY_PLAN.BILLING.PLAN_DETAIL.button')}
                      </Button>
                    </If>
                    <If
                      condition={
                        !canAddToAutoDebit &&
                        !isFreemiumClient &&
                        !isOldPlan &&
                        billingInformation?.hasStripeSubscription
                      }
                    >
                      <ButtonWithDropdown
                        dropdownItems={dropdownItems}
                        id="manage-plan"
                        isLoading={billingPortalIsLoading}
                        onClick={handleClickManagePlan}
                      >
                        {t('MY_PLAN.BILLING.PLAN_DETAIL.button')}
                      </ButtonWithDropdown>
                    </If>
                  </If>
                </If>
              </div>
            </div>
          </div>
        </CardSection>
      </Card>
      <ModalInformation
        canEdit={hasRoleAdmin}
        isOpen={moreInformationIsOpen}
        onClose={setMoreInformationIsOpen}
      />
    </>
  );
};

export default memo(HeaderMyPlan);
