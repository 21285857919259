import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import TopNav from '@/lib/components/TopNav';

import { useUserData } from './infrastructure/services/useUserData';

const ContractsBillings = lazy(() => import('./modules/ContractsModule/screens/ContractsBillings'));
const ContractsForm = lazy(() => import('./modules/ContractsModule/screens/ContractsForm'));
const ContractsLogs = lazy(() => import('./modules/ContractsModule/screens/ContractsLogs'));
const ContractsMain = lazy(() => import('./modules/ContractsModule/screens/ContractsMain'));
const PlansMain = lazy(() => import('./modules/PlansModule/screens/PlansMain'));
const SmsMain = lazy(() => import('./modules/SmsModule/screens/SmsMain'));
const SurplusMain = lazy(() => import('./modules/SurplusModule/screens/SurplusMain'));

const BackOffice = () => {
  const [userData] = useUserData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (!userData.email.includes('@embluemail.com')) {
      navigate('/v2');
    }
  }, [userData, navigate]);

  return (
    <Suspense>
      <Routes>
        <Route element={<TopNav />} path="/">
          <Route index element={<Navigate to={`/v2/backoffice/contracts`} />} path="/" />
          <Route element={<ContractsMain />} path="/contracts" />
          <Route element={<ContractsLogs />} path="/contracts/logs" />
          <Route element={<ContractsBillings />} path="/contracts/billings" />
          <Route element={<ContractsForm />} path="/contracts/detail/:id" />
          <Route element={<PlansMain />} path="/plans" />
          <Route element={<SmsMain />} path="/sms" />
          <Route element={<SurplusMain />} path="/surplus" />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default BackOffice;
