import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CardSection, Flex, If, Spinner, toast } from '@/lib/v2/components';

import { confirmMailTranslationKeyError } from './util';

import {
  atomActionInfoEmail,
  atomEmailAddresseesInfo,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomActionTypePath,
  atomCallbackPauseStep,
  atomCallbackStep,
  atomHeaderActionLabelButton,
  atomHeaderActionTypeLastStep,
  atomToggleConfirmActionModal,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionData } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { MessageDisplay } from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/components/MessageDisplay';
import { EmailSummary } from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailSummary';
import { ConfirmAction } from '@/modules/CampaignsModule/components/CampaignsModal/ConfirmAction';
import { SummarySkeleton } from '@/modules/CampaignsModule/components/SummaryAction/SummarySkeleton';
import {
  STATES_PROCESS_ACTION,
  TRANSLATE_TOAST_CONFIRM_ACTION,
} from '@/modules/CampaignsModule/constants';
import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import { EActionTypesPath } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const EmailSummaryMain = () => {
  const { t } = useTranslation();
  const { actionId, campaignId } = useParams();
  const navigate = useNavigate();

  const [actionInfoEmail, setActionInfoEmail] = useAtom(atomActionInfoEmail);
  const [emailAddresseesInfo, setEmailAddresseesInfo] = useAtom(atomEmailAddresseesInfo);
  const [actionTypePath, setActionTypePath] = useAtom(atomActionTypePath);

  const actionData = useAtomValue(atomActionData);

  const setActionTypeLastStep = useSetAtom(atomHeaderActionTypeLastStep);
  const setCallbackStep = useSetAtom(atomCallbackStep);
  const setCallbackPauseStep = useSetAtom(atomCallbackPauseStep);
  const setHeaderActionLabelButton = useSetAtom(atomHeaderActionLabelButton);
  const setToggleConfirmActionModal = useSetAtom(atomToggleConfirmActionModal);

  const {
    getEmailInfo,
    setConfirmEmail,
    pauseCancelEmail,
    getAddresseesInfo,
    getTotalAddresseesByAction,
    getSummaryTotalRecipients,
    loading,
  } = useActionEmailData(Number(actionId));
  const { refetch } = useActionData(Number(actionId));

  const { confirmableDraft, active, finished } = STATES_PROCESS_ACTION;
  const { content: messageHTML } = actionInfoEmail || {};
  const { status } = actionData || {};

  useLayoutEffect(() => {
    setActionTypePath(EActionTypesPath.EMAIL);
    void (async () => {
      await getEmailInfo();
      await getAddresseesInfo();
    })();

    return () => {
      setActionInfoEmail(undefined);
      setEmailAddresseesInfo(undefined);
    };
  }, [
    getEmailInfo,
    getAddresseesInfo,
    setActionInfoEmail,
    setEmailAddresseesInfo,
    setActionTypePath,
  ]);

  useEffect(() => {
    const labelButton = emailAddresseesInfo?.isImmediateShipping
      ? t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.send')
      : t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.scheduled');

    setHeaderActionLabelButton(labelButton);

    return () =>
      setHeaderActionLabelButton(t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.continue'));
  }, [emailAddresseesInfo, setHeaderActionLabelButton, t]);

  useEffect(() => {
    setActionTypeLastStep(true);
    return () => setActionTypeLastStep(false);
  }, [setActionTypeLastStep]);

  useEffect(() => {
    if (!actionInfoEmail?.groupsAndLists) return;

    finished.includes(Number(status))
      ? void getSummaryTotalRecipients(Number(actionId))
      : void getTotalAddresseesByAction(actionInfoEmail?.groupsAndLists);
  }, [
    getTotalAddresseesByAction,
    getSummaryTotalRecipients,
    actionInfoEmail?.groupsAndLists,
    finished,
    status,
    actionId,
  ]);

  const handleConfirmStep = useCallback(
    () => setToggleConfirmActionModal(true),
    [setToggleConfirmActionModal]
  );

  const handlePauseStep = useCallback(async () => {
    if (active.includes(Number(status))) {
      const eventType =
        !emailAddresseesInfo?.isImmediateShipping && status === 3 ? 'pause' : 'cancel';

      await pauseCancelEmail(eventType);
      if (eventType === 'pause') {
        refetch();
        navigate(`/v2/campaigns/${String(campaignId)}/${actionTypePath}/${String(actionId)}`);
      } else {
        navigate(`/v2/campaigns/${String(campaignId)}`);
      }
    }
  }, [
    active,
    status,
    emailAddresseesInfo?.reinforcement.isReinforcementShipment,
    pauseCancelEmail,
    refetch,
    navigate,
    campaignId,
    actionTypePath,
    actionId,
  ]);

  const handleConfirmAction = useCallback(async () => {
    if (!confirmableDraft.includes(Number(status))) return;

    /* const isImmediateShipping: boolean = emailAddresseesInfo?.isImmediateShipping || false; */

    const { success, errorType } = await setConfirmEmail();
    setToggleConfirmActionModal(false);
    /* const messages = messagesForSchedulingType(isImmediateShipping, !responseConfirmEmail); */

    if (success) {
      toast({
        title: t(`${TRANSLATE_TOAST_CONFIRM_ACTION}.SUCCESS_ACTION.title`),
        body: t(`${TRANSLATE_TOAST_CONFIRM_ACTION}.SUCCESS_ACTION.message`),
        variant: 'success',
      });
    } else {
      const translationKey =
        confirmMailTranslationKeyError.get(errorType ?? 'UNKNOWN') ?? 'UNKNOWN';
      toast({
        title: t(`${TRANSLATE_TOAST_CONFIRM_ACTION}.ERROR.title`),
        body: t(`${TRANSLATE_TOAST_CONFIRM_ACTION}.ERROR.${translationKey}`),
        variant: 'error',
      });
    }

    navigate(`/v2/campaigns/${String(campaignId)}`);
  }, [
    confirmableDraft,
    status,
    setConfirmEmail,
    setToggleConfirmActionModal,
    t,
    navigate,
    campaignId,
  ]);

  useEffect(() => {
    setCallbackStep(() => handleConfirmStep);
  }, [setCallbackStep, handleConfirmStep]);

  useEffect(() => {
    setCallbackPauseStep(() => handlePauseStep);
  }, [setCallbackPauseStep, handlePauseStep]);

  return (
    <div className="relative bg-white">
      <CardSection noPadding>
        <Flex column>
          <Flex itemAlignment="start">
            <div className="my-12 w-1/2 bg-white px-10">
              {loading && !actionInfoEmail && !emailAddresseesInfo ? (
                <SummarySkeleton />
              ) : (
                <EmailSummary />
              )}
            </div>
            <div className="flex min-h-[calc(100dvh-145px)] w-1/2 items-center justify-center bg-[#F4F5FA] ">
              <If condition={loading}>
                <Spinner withoutOverlay />
              </If>
              <If condition={!loading}>
                {messageHTML && <MessageDisplay hasOverlay={false} html={messageHTML} />}
              </If>
            </div>
          </Flex>
        </Flex>
      </CardSection>
      <ConfirmAction loading={loading} onConfirm={handleConfirmAction} />
    </div>
  );
};

export default EmailSummaryMain;
