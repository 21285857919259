import { IInboxEvent } from './IInboxService';

//cspell:disable
export const mockInboxEvents: IInboxEvent[] = [
  {
    id: 1,
    contactId: 38,
    conversationId: 67890,
    agentId: 101,
    agentName: 'John Smith',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about billing',
  },
  {
    id: 2,
    contactId: 39,
    conversationId: 67891,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'resolved',
    channel: 'facebook',
    description: 'Complaint about defective product',
  },
  {
    id: 3,
    contactId: 40,
    conversationId: 67892,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'pending',
    channel: 'instagram',
    description: 'Request for shipping information',
  },
  {
    id: 4,
    contactId: 41,
    conversationId: 67893,
    agentId: 101,
    agentName: 'John Smith',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about product availability',
  },
  {
    id: 5,
    contactId: 42,
    conversationId: 67894,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'resolved',
    channel: 'facebook',
    description: 'Request for product exchange',
  },
  {
    id: 6,
    contactId: 43,
    conversationId: 67895,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Inquiry about payment methods',
  },
  {
    id: 7,
    contactId: 44,
    conversationId: 67896,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'pending',
    channel: 'instagram',
    description: 'Complaint about delivery time',
  },
  {
    id: 8,
    contactId: 45,
    conversationId: 67897,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Return request',
  },
  {
    id: 9,
    contactId: 46,
    conversationId: 67898,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'resolved',
    channel: 'facebook',
    description: 'Inquiry about product warranty',
  },
  {
    id: 10,
    contactId: 47,
    conversationId: 67899,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Question about product features',
  },
  {
    id: 11,
    contactId: 48,
    conversationId: 67900,
    agentId: 101,
    agentName: 'John Smith',
    status: 'pending',
    channel: 'instagram',
    description: 'Inquiry about current promotions',
  },
  {
    id: 12,
    contactId: 49,
    conversationId: 67901,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Issue with mobile application',
  },
  {
    id: 13,
    contactId: 50,
    conversationId: 67902,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'pending',
    channel: 'facebook',
    description: 'Request for product customization',
  },
  {
    id: 14,
    contactId: 51,
    conversationId: 67903,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'resolved',
    channel: 'instagram',
    description: 'Feedback on recent purchase',
  },
  {
    id: 15,
    contactId: 52,
    conversationId: 67904,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about bulk order discounts',
  },
  {
    id: 16,
    contactId: 53,
    conversationId: 67905,
    agentId: 101,
    agentName: 'John Smith',
    status: 'resolved',
    channel: 'facebook',
    description: 'Question about product specifications',
  },
  {
    id: 17,
    contactId: 54,
    conversationId: 67906,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'pending',
    channel: 'instagram',
    description: 'Complaint about customer service',
  },
  {
    id: 18,
    contactId: 55,
    conversationId: 67907,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Request for product demo',
  },
  {
    id: 19,
    contactId: 56,
    conversationId: 67908,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'pending',
    channel: 'facebook',
    description: 'Inquiry about gift wrapping options',
  },
  {
    id: 20,
    contactId: 57,
    conversationId: 67909,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'resolved',
    channel: 'instagram',
    description: 'Question about product compatibility',
  },
  {
    id: 21,
    contactId: 58,
    conversationId: 67910,
    agentId: 101,
    agentName: 'John Smith',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about store locations',
  },
  {
    id: 22,
    contactId: 59,
    conversationId: 67911,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'resolved',
    channel: 'facebook',
    description: 'Request for product manual',
  },
  {
    id: 23,
    contactId: 60,
    conversationId: 67912,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'pending',
    channel: 'instagram',
    description: 'Complaint about website functionality',
  },
  {
    id: 24,
    contactId: 61,
    conversationId: 67913,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Inquiry about product restocking',
  },
  {
    id: 25,
    contactId: 62,
    conversationId: 67914,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'pending',
    channel: 'facebook',
    description: 'Question about product ingredients',
  },
  {
    id: 26,
    contactId: 63,
    conversationId: 67915,
    agentId: 101,
    agentName: 'John Smith',
    status: 'resolved',
    channel: 'instagram',
    description: 'Request for product sample',
  },
  {
    id: 27,
    contactId: 64,
    conversationId: 67916,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about loyalty program points',
  },
  {
    id: 28,
    contactId: 65,
    conversationId: 67917,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'resolved',
    channel: 'facebook',
    description: 'Complaint about delivery service',
  },
  {
    id: 29,
    contactId: 66,
    conversationId: 67918,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'pending',
    channel: 'instagram',
    description: 'Question about product sizing',
  },
  {
    id: 30,
    contactId: 67,
    conversationId: 67919,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Inquiry about gift cards',
  },
  {
    id: 31,
    contactId: 68,
    conversationId: 67920,
    agentId: 101,
    agentName: 'John Smith',
    status: 'pending',
    channel: 'facebook',
    description: 'Request for product comparison',
  },
  {
    id: 32,
    contactId: 69,
    conversationId: 67921,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'resolved',
    channel: 'instagram',
    description: 'Complaint about product quality',
  },
  {
    id: 33,
    contactId: 70,
    conversationId: 67922,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about international shipping',
  },
  {
    id: 34,
    contactId: 71,
    conversationId: 67923,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'resolved',
    channel: 'facebook',
    description: 'Question about product assembly',
  },
  {
    id: 35,
    contactId: 72,
    conversationId: 67924,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'pending',
    channel: 'instagram',
    description: 'Request for product recommendation',
  },
  {
    id: 36,
    contactId: 73,
    conversationId: 67925,
    agentId: 101,
    agentName: 'John Smith',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Inquiry about return policy',
  },
  {
    id: 37,
    contactId: 74,
    conversationId: 67926,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'pending',
    channel: 'facebook',
    description: 'Complaint about packaging',
  },
  {
    id: 38,
    contactId: 75,
    conversationId: 67927,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'resolved',
    channel: 'instagram',
    description: 'Question about product maintenance',
  },
  {
    id: 39,
    contactId: 76,
    conversationId: 67928,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about bulk purchases',
  },
  {
    id: 40,
    contactId: 77,
    conversationId: 67929,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'resolved',
    channel: 'facebook',
    description: 'Request for product catalog',
  },
  {
    id: 41,
    contactId: 78,
    conversationId: 67930,
    agentId: 101,
    agentName: 'John Smith',
    status: 'pending',
    channel: 'instagram',
    description: 'Complaint about customer support',
  },
  {
    id: 42,
    contactId: 79,
    conversationId: 67931,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Inquiry about product customization',
  },
  {
    id: 43,
    contactId: 80,
    conversationId: 67932,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'pending',
    channel: 'facebook',
    description: 'Question about product availability',
  },
  {
    id: 44,
    contactId: 81,
    conversationId: 67933,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'resolved',
    channel: 'instagram',
    description: 'Request for product demonstration',
  },
  {
    id: 45,
    contactId: 82,
    conversationId: 67934,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about warranty extension',
  },
  {
    id: 46,
    contactId: 83,
    conversationId: 67935,
    agentId: 101,
    agentName: 'John Smith',
    status: 'resolved',
    channel: 'facebook',
    description: 'Complaint about shipping costs',
  },
  {
    id: 47,
    contactId: 84,
    conversationId: 67936,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'pending',
    channel: 'instagram',
    description: 'Question about product compatibility',
  },
  {
    id: 48,
    contactId: 85,
    conversationId: 67937,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Inquiry about product certifications',
  },
  {
    id: 49,
    contactId: 86,
    conversationId: 67938,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'pending',
    channel: 'facebook',
    description: 'Request for product brochure',
  },
  {
    id: 50,
    contactId: 87,
    conversationId: 67939,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'resolved',
    channel: 'instagram',
    description: 'Complaint about order cancellation',
  },
  {
    id: 51,
    contactId: 88,
    conversationId: 67940,
    agentId: 101,
    agentName: 'John Smith',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about product ingredients',
  },
  {
    id: 52,
    contactId: 89,
    conversationId: 67941,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'resolved',
    channel: 'facebook',
    description: 'Question about product durability',
  },
  {
    id: 53,
    contactId: 90,
    conversationId: 67942,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'pending',
    channel: 'instagram',
    description: 'Request for product installation guide',
  },
  {
    id: 54,
    contactId: 91,
    conversationId: 67943,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Inquiry about product safety features',
  },
  {
    id: 55,
    contactId: 92,
    conversationId: 67944,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'pending',
    channel: 'facebook',
    description: 'Complaint about product color mismatch',
  },
  {
    id: 56,
    contactId: 93,
    conversationId: 67945,
    agentId: 101,
    agentName: 'John Smith',
    status: 'resolved',
    channel: 'instagram',
    description: 'Question about product eco-friendliness',
  },
  {
    id: 57,
    contactId: 94,
    conversationId: 67946,
    agentId: 102,
    agentName: 'Mary Johnson',
    status: 'pending',
    channel: 'whatsapp',
    description: 'Inquiry about product warranty coverage',
  },
  {
    id: 58,
    contactId: 95,
    conversationId: 67947,
    agentId: 103,
    agentName: 'Charles Brown',
    status: 'resolved',
    channel: 'facebook',
    description: 'Request for product user manual',
  },
  {
    id: 59,
    contactId: 96,
    conversationId: 67948,
    agentId: 104,
    agentName: 'Anna Davis',
    status: 'pending',
    channel: 'instagram',
    description: 'Complaint about website navigation',
  },
  {
    id: 60,
    contactId: 97,
    conversationId: 67949,
    agentId: 105,
    agentName: 'Louis Wilson',
    status: 'resolved',
    channel: 'whatsapp',
    description: 'Inquiry about loyalty program benefits',
  },
];

export const [inboxFilters] = [
  {
    channels: [{ id: 'whatsapp', name: 'WhatsApp' }],
    activities: [
      { id: 'resolved', name: 'Resolved' },
      { id: 'pending', name: 'Pending' },
    ],
  },
];
//cspell:enable
