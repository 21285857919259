import { GenericAbortSignal } from 'axios';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import {
  IGetAllFlowsPayload,
  IGetExecutedCountResponse,
  IGetReportEventsPayload,
  IGetReportFlowsPayload,
} from '@/src/infrastructure/interfaces/IAutomation.interface';
import { useAutomationService } from '@/src/infrastructure/Protocol/Automation/useBeefreeService';
import {
  atomEvents,
  atomFilterType,
  atomItemDetailEventsExecution,
  atomShowDetailEventsExecution,
} from '@/src/modules/AutomationModule/atoms/events';
import {
  atomExecutedCountIsLoading,
  atomExecutedEvents,
  atomFilterHistorical,
  atomFilterStatus,
  atomFilterTriggers,
  atomFlows,
  atomFlowsFilters,
  atomFlowsLoadingProcess,
  atomOrderBy,
  atomPagerFlows,
  atomSearchFlows,
  atomTotalsProcess,
  getDefaultFilterHistorical,
} from '@/src/modules/AutomationModule/atoms/flows';

import { IAutomationEvent, IEventsRowTable } from '@/modules/AutomationModule/types/events';
import {
  IFilterExecution,
  IFlowData,
  IFlowRowTable,
  IFlowsTableFilters,
  ITablePager,
} from '@/modules/AutomationModule/types/flows';

export type DataType = 'flows' | 'events';

interface IHandleRefetch extends Partial<IFlowsTableFilters> {
  withoutLoading?: boolean;
}

export const useHandleEventsFlows = (dataType: DataType = 'flows') => {
  const { t } = useTranslation();
  const serviceAutomation = useAutomationService();
  const isFlows = dataType === 'flows';
  const [executedCountIsLoading, setExecutedCountIsLoading] = useAtom(atomExecutedCountIsLoading);
  const [pageParams, setPageParams] = useAtom(atomPagerFlows);
  const [data, setData] = useAtom(isFlows ? atomFlows : atomEvents);
  const setExecutedCount = useSetAtom(atomExecutedEvents);
  const setTotalsFlowsList = useSetAtom(atomTotalsProcess);
  const [loadingProcess, setLoadingProcess] = useAtom(atomFlowsLoadingProcess);
  const [filters, setFilters] = useAtom(atomFlowsFilters);
  const [historical, setHistorical] = useAtom(atomFilterHistorical);
  const [search, setSearch] = useAtom(atomSearchFlows);
  const [showOpenModalExecutions, setOpenModalExecutions] = useAtom(atomShowDetailEventsExecution);
  const [itemModalExecutions, setItemModalExecutions] = useAtom(atomItemDetailEventsExecution);
  const setStatus = useSetAtom(atomFilterStatus);
  const setTriggers = useSetAtom(atomFilterTriggers);
  const setTypes = useSetAtom(atomFilterType);
  const orderBy = useAtomValue(atomOrderBy);
  const controllerExecutions = useRef<AbortController>();

  const getExecutedCount = useCallback(
    async (data: IFlowData[] | IAutomationEvent[], filters: IFilterExecution) => {
      let response: IGetExecutedCountResponse;

      setExecutedCountIsLoading(true);

      if (controllerExecutions.current) {
        controllerExecutions.current.abort();
      }

      const controller = new AbortController();
      controllerExecutions.current = controller;

      if (isFlows) {
        const triggerIds = (data as IFlowData[])
          .filter((flow: IFlowData) => !!flow.triggerId)
          .map((flow: IFlowData) => flow.triggerId ?? 0);
        response = await serviceAutomation.getFlowsExecutions(
          triggerIds,
          filters,
          controllerExecutions.current.signal
        );
      } else {
        const eventsIds = (data as IAutomationEvent[]).map((event: IAutomationEvent) => event.id);
        response = await serviceAutomation.getEventsExecutions(
          eventsIds,
          filters,
          controllerExecutions.current.signal
        );
      }
      setExecutedCountIsLoading(false);
      response && setExecutedCount(response);
    },
    [isFlows, serviceAutomation, setExecutedCount, setExecutedCountIsLoading]
  );

  const getData = useCallback(
    async (payload: IHandleRefetch = filters) => {
      if (!payload.withoutLoading) setLoadingProcess(true);
      const { types = null, triggers = null, status = null, pageIndex, pageSize, search } = payload;

      const dataPayload: IGetAllFlowsPayload = {
        triggers,
        status,
        types,
        page: pageIndex || 1,
        limit: pageSize || 10,
        search: search || null,
        ...(payload.orderBy ?? orderBy),
      };

      const { result, pager } =
        dataType === 'flows'
          ? await serviceAutomation.getFlows(dataPayload)
          : await serviceAutomation.getEvents(dataPayload);

      const filter = {
        dateFrom: dataPayload?.dateFrom ?? historical?.dateFrom,
        dateTo: dataPayload?.dateTo ?? historical?.dateTo,
        counter: dataPayload.counter || false,
      };

      if (dataType === 'flows') {
        const flows = (result as IFlowData[]).map((flow: IFlowData) => ({ flow }));
        if (flows) {
          getExecutedCount(result as IFlowData[], filter).catch(console.error);
          setData(flows);
        }
      } else {
        const events = (result as IAutomationEvent[]).map((event: IAutomationEvent) => ({ event }));
        if (events) {
          getExecutedCount(result as IAutomationEvent[], filter).catch(console.error);
          setData(events);
        }
      }

      setTotalsFlowsList({
        total: pager.countTotal,
        partial: pager.countPartial,
      });
      if (!payload.withoutLoading) setLoadingProcess(false);
    },
    [
      historical,
      dataType,
      filters,
      getExecutedCount,
      orderBy,
      serviceAutomation,
      setData,
      setLoadingProcess,
      setTotalsFlowsList,
    ]
  );

  const handleExecutedCount = useCallback(
    async (
      historical: {
        dateFrom: string;
        dateTo: string;
        counter: boolean;
      },
      list: IFlowRowTable[] | IEventsRowTable[] = data
    ) => {
      setExecutedCountIsLoading(true);
      if (dataType === 'flows') {
        const listFlows = (list as IFlowRowTable[]).map((flow: IFlowRowTable) => flow.flow);
        await getExecutedCount(listFlows, historical);
      } else {
        const listEvents = (list as IEventsRowTable[]).map((event: IEventsRowTable) => event.event);
        await getExecutedCount(listEvents, historical);
      }
      setExecutedCountIsLoading(false);
    },
    [data, dataType, getExecutedCount, setExecutedCountIsLoading]
  );

  const handleChangeTablePage = useCallback(
    (payload?: ITablePager) => {
      const { pageIndex = 1, pageSize = 10 } = payload ?? {};
      const newLimit = pageSize;
      const newPage = pageIndex + 1;
      if (pageParams?.page !== newPage || pageParams?.limit !== newLimit) {
        setPageParams({ page: newPage, limit: newLimit });

        void getData({
          ...filters,
          pageIndex: newPage,
          pageSize: newLimit,
        });
      }
    },
    [filters, getData, pageParams?.limit, pageParams?.page, setPageParams]
  );

  const handleRefetch = useCallback(
    async (payload?: IHandleRefetch) => {
      await getData(payload);
    },
    [getData]
  );

  const downloadReport = useCallback(
    async (payload: IGetReportFlowsPayload | IGetReportEventsPayload) => {
      const dateFrom = payload.dateFrom ? payload.dateFrom : historical?.dateFrom?.substring(0, 10);
      const dateTo = payload.dateTo ? payload.dateTo : historical?.dateTo?.substring(0, 10);
      let url: string | null = '';
      if (isFlows) {
        url = await serviceAutomation.getReportFlows({
          ...(payload as IGetReportFlowsPayload),
          dateFrom,
          dateTo,
        });
      } else {
        url = await serviceAutomation.getReportEvents({
          ...(payload as IGetReportEventsPayload),
          dateFrom,
          dateTo,
        });
      }

      if (!url) return;

      const blob = await serviceAutomation.downloadReport(url);

      if (!blob) return;

      const name = url.split('/').pop() || 'report.zip';
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = isFlows ? name : name.replace('flow_', 'event_');
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);
    },
    [historical, isFlows, serviceAutomation]
  );

  const clearFilters = useCallback(
    (withRefetch = true) => {
      const resetFilters = {
        pageIndex: 1,
        pageSize: 10,
        status: [],
        triggers: [],
        types: [],
        search: '',
      };
      setFilters(resetFilters);
      setHistorical(getDefaultFilterHistorical());
      setStatus([]);
      setTriggers([]);
      setTypes([]);
      setSearch('');
      withRefetch && void handleRefetch(resetFilters);
    },
    [handleRefetch, setFilters, setHistorical, setSearch, setStatus, setTriggers, setTypes]
  );

  const handleOpenModalExecutions = useCallback(
    (item: IAutomationEvent | IFlowData) => {
      setOpenModalExecutions(true);
      setItemModalExecutions(item);
    },
    [setItemModalExecutions, setOpenModalExecutions]
  );
  const handleCloseModalExecutions = useCallback(() => {
    setOpenModalExecutions(false);
    setItemModalExecutions(null);
  }, [setItemModalExecutions, setOpenModalExecutions]);

  const handleExecution = useCallback(
    async (id: number) => {
      const response = await serviceAutomation.executeFlow(id);

      toast({
        title: response
          ? t('AUTOMATION_MAIN_MODULE.EXECUTION.NOTIFY_OK.title')
          : t('AUTOMATION_MAIN_MODULE.EXECUTION.NOTIFY_ERROR.title'),
        body: response
          ? t('AUTOMATION_MAIN_MODULE.EXECUTION.NOTIFY_OK.body')
          : t('AUTOMATION_MAIN_MODULE.EXECUTION.NOTIFY_ERROR.body'),
        variant: response ? 'success' : 'error',
      });

      response &&
        (await handleRefetch({
          withoutLoading: false,
        }));
    },
    [handleRefetch, serviceAutomation, t]
  );

  return {
    getData,
    downloadReport,
    data,
    historical,
    filters,
    clearFilters,
    handleChangeTablePage,
    loadingProcess,
    search,
    setSearch,
    handleRefetch,
    handleOpenModalExecutions,
    itemModalExecutions,
    showOpenModalExecutions,
    handleCloseModalExecutions,
    handleExecutedCount,
    handleExecution,
    executedCountIsLoading,
    orderBy,
  };
};
