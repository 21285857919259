/* eslint-disable pii/no-email */
/** cspell:disable */
interface CurrencyFormat {
  symbol: string;
  position: 'left' | 'right';
  thousand: string;
  decimal: string;
}

const emailToCountry: Record<string, string> = {
  'administracion@embluemail.com': 'Argentina',
  'administracioncolombia@embluemail.com': 'Colombia',
  'administracionperu@embluemail.com': 'Peru',
  'admin.chile@embluemail.com': 'Chile',
  'invoicemblueusa@embluemail.com': 'USA',
  'facturacionmexico@embluemail.com': 'Mexico',
};

const countryFormats: Record<string, CurrencyFormat> = {
  Argentina: { symbol: '$', position: 'left', thousand: '.', decimal: ',' },
  Colombia: { symbol: '$', position: 'left', thousand: ',', decimal: '.' },
  Peru: { symbol: 'S/', position: 'left', thousand: ',', decimal: '.' },
  Chile: { symbol: '$', position: 'left', thousand: '.', decimal: ',' },
  USA: { symbol: '$', position: 'left', thousand: ',', decimal: '.' },
  Mexico: { symbol: '$', position: 'left', thousand: ',', decimal: '.' },
};

export const formatPriceByCountryEmail = (email: string, price: number): string => {
  const country = emailToCountry[email];
  if (!country) {
    throw new Error('Email no reconocido');
  }

  const format = countryFormats[country];
  if (!format) {
    throw new Error('Formato de moneda no disponible para el país');
  }

  const decimals = price < 1 ? 5 : 2;

  const parts = price.toFixed(decimals).split('.');
  let integerPart = parts[0];
  const fractionalPart = parts[1];

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, format.thousand);

  let formattedPrice = integerPart + format.decimal + fractionalPart;
  if (format.position === 'left') {
    formattedPrice = format.symbol + ' ' + formattedPrice;
  } else {
    formattedPrice = formattedPrice + ' ' + format.symbol;
  }

  return formattedPrice;
};

export const formatPriceByCountry = (country: string, price: number) => {
  if (country && price) {
    const format = countryFormats[country];
    if (!format) {
      return;
    }

    const decimals = price < 1 ? 5 : 2;

    const parts = price.toFixed(decimals).split('.');
    let integerPart = parts[0];
    const fractionalPart = parts[1];

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, format.thousand);

    let formattedPrice = integerPart + format.decimal + fractionalPart;
    if (format.position === 'left') {
      formattedPrice = format.symbol + ' ' + formattedPrice;
    } else {
      formattedPrice = formattedPrice + ' ' + format.symbol;
    }

    return formattedPrice;
  }
};
/** cspell:enable */
