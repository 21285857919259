import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Option } from '@/lib/v2/components';

import { atomIsActionInfoEmailErrors } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomEmailPreview,
  atomPreviewContactId,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { usePreviewContacts } from '@/modules/CampaignsModule/hooks/usePreviewContacts';
import { useSendEmailTest } from '@/modules/CampaignsModule/hooks/useSendEmailTest';

export const useSelectorPreview = () => {
  const { campaignId, actionId, actionType } = useParams();
  const navigate = useNavigate();

  const [emailPreview] = useAtom(atomEmailPreview);
  const setPreviewContactId = useSetAtom(atomPreviewContactId);
  const haveErrors = useAtomValue(atomIsActionInfoEmailErrors);

  const [previewContactSelected, setPreviewContactSelected] = useState<Option | undefined>(
    undefined
  );
  const [previewGroupSelected, setPreviewGroupSelected] = useState<Option | undefined>(undefined);

  const { sendTestEmail, loading: sendingEmailTest } = useSendEmailTest();
  const { previewContacts, previewGroups, loading: loadingData } = usePreviewContacts();
  const { subject } = emailPreview || {};

  useEffect(() => {
    if (previewContacts?.length === 0) {
      setPreviewContactId(0);
    }
    if (previewContacts && previewContacts?.length > 0) {
      setPreviewContactSelected(previewContacts[0]);
      setPreviewContactId(Number(previewContacts[0].id));
    }
  }, [previewContacts, setPreviewContactId, setPreviewContactSelected]);

  useEffect(() => {
    if (previewGroups && previewGroups.length > 0) {
      setPreviewGroupSelected(previewGroups[0]);
    }
  }, [previewGroups, setPreviewGroupSelected]);

  const isButtonDisabled = useMemo(() => {
    return !previewGroupSelected || !subject || haveErrors;
  }, [haveErrors, previewGroupSelected, subject]);

  const handleChangeContact = useCallback(
    (value: Option | Option[]) => {
      const contact = value as Option;
      setPreviewContactSelected(contact);
      setPreviewContactId(Number(contact.id));
    },
    [setPreviewContactSelected, setPreviewContactId]
  );

  const handleChangeGroup = useCallback(
    (value: Option | Option[]) => {
      const group = value as Option;
      setPreviewGroupSelected(group);
    },
    [setPreviewGroupSelected]
  );

  const handleSendTest = useCallback(async () => {
    if (!actionId || !previewGroupSelected) return;
    await sendTestEmail(+actionId, previewContactSelected ?? ({} as Option), previewGroupSelected);
  }, [actionId, previewContactSelected, previewGroupSelected, sendTestEmail]);

  const handleEditEmail = useCallback(() => {
    navigate(`${CAMPAIGNS_PATH}/${Number(campaignId)}/${actionType}/${Number(actionId)}/editor`);
  }, [actionId, actionType, campaignId, navigate]);

  return {
    events: {
      handleSendTest,
      handleChangeGroup,
      handleChangeContact,
      handleEditEmail,
    },
    state: {
      sourceEditor: emailPreview?.content.editorVersion,
      loadingData,
      previewContacts,
      previewContactSelected,
      previewGroups,
      previewGroupSelected,
      isButtonDisabled,
      sendingEmailTest,
      subject,
    },
  };
};
