/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Table } from '@/lib/components';
import { IconSvg } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyActivityIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';
import { ChatIcon } from '@/lib/v2/icons/solid';

import { getEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import { IInboxEvent } from '@/src/infrastructure/inbox/IInboxService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useDate } from '@/src/infrastructure/services/useDate';

interface ITableContactProfileInboxProps {
  inboxEventList: IInboxEvent[];
  inboxEventTotalCount: number;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
}

export function ContactsProfileInboxTable({
  inboxEventList,
  inboxEventTotalCount,
  changeTablePage,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
}: ITableContactProfileInboxProps) {
  const { t } = useTranslation(['inbox', 'translation']);
  const { dateFormatter } = useDate();
  const service = useService();

  const conversationColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{`${t(
          'INBOX.TABLE.headers.conversation'
        )}`}</span>
      </Flex>
    ),
    accessor: 'conversation',
    id: 'conversation',
    Cell: ({ row }: { row: { original: IInboxEvent } }) => (
      <Flex withGap alignment="start">
        <IconSvg svgComponent={<ChatIcon />} />
        <span className="self-start px-1 text-[#364365]">
          <strong>{`#${row.original.conversationId}`}</strong>
        </span>
        <span className="max-w-56 self-start truncate text-[#364365] xl:max-w-64">
          {row.original.description}
        </span>
      </Flex>
    ),
  };
  const createdAtColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{`${t(
          'INBOX.TABLE.headers.createdAt'
        )}`}</span>
      </Flex>
    ),
    accessor: 'createdAt',
    id: 'createdAt',
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">
          <>{value && dateFormatter(value)}</>
        </span>
      </Flex>
    ),
  };
  const channelColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{`${t('INBOX.TABLE.headers.channel')}`}</span>
      </Flex>
    ),
    accessor: 'channel',
    id: 'channel',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{`${t(
          `INBOX.TABLE.channels.${String(value)}`
        )}`}</span>
      </Flex>
    ),
  };
  const agentColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{`${t('INBOX.TABLE.headers.agent')}`}</span>
      </Flex>
    ),
    accessor: 'agentName',
    id: 'agentName',
    Cell: ({ row }: { row: { original: IInboxEvent } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">
          {row.original.agentId === null
            ? t('INBOX.TABLE.agent.unassigned')
            : row.original.agentName ?? '-'}
        </span>
      </Flex>
    ),
  };
  const statusColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{`${t('INBOX.TABLE.headers.status')}`}</span>
      </Flex>
    ),
    accessor: 'status',
    id: 'status',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">
          {t(`INBOX.TABLE.status.${value}`, {
            defaultValue: value,
          })}
        </span>
      </Flex>
    ),
  };

  const activityTableColumns: Column<object>[] = [
    conversationColumn,
    createdAtColumn,
    channelColumn,
    agentColumn,
    statusColumn,
  ].filter((x) => x) as Column<object>[];

  const env = getEnv();
  const baseUrl = configData.endpoints[env === 'storybook' ? 'development' : env].URL_INBOX;

  const goToConversation = async (conversationId: number) => {
    try {
      let chatwootAccountId = 1;
      try {
        const chatwootAccountIdResponse = await service?.getChatwootAccountId();
        if (chatwootAccountIdResponse && chatwootAccountIdResponse.chatwoot_account_id) {
          chatwootAccountId = chatwootAccountIdResponse.chatwoot_account_id;
        }
      } catch (error) {
        console.error('Error getting Chatwoot account ID:', error);
      }

      const finalUrl = `${baseUrl}/app/accounts/${chatwootAccountId}/conversations/${conversationId}`;
      window.open(finalUrl, '_blank');
    } catch (error) {
      console.error('Error getting Chatwoot account ID:', error);
    }
  };

  return (
    <div className="size-full text-base text-[#364365] 2xl:text-14">
      <Table
        // eslint-disable-next-line react/no-children-prop
        children={<></>}
        isPaginateOnOverflow
        classNamePagination="pr-4"
        columns={activityTableColumns}
        data={inboxEventList}
        emptyScreen={{
          totalCount: inboxEventTotalCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('INBOX.EMPTY_SCREEN_TABLE.actionText')}
              description={t('INBOX.EMPTY_SCREEN_TABLE.noResultDescription')}
              image={<EmptyContactsIcon />}
              title={t('INBOX.EMPTY_SCREEN_TABLE.noResultTitle')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              actionText={t('INBOX.EMPTY_SCREEN_TABLE.actionText')}
              description={t('INBOX.EMPTY_SCREEN_TABLE.noDataDescription')}
              image={<EmptyActivityIcon />}
              title={t('INBOX.EMPTY_SCREEN_TABLE.noDataTitle')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        withCheckbox={false}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
        onRowClick={(value) => {
          void goToConversation(value.conversationId);
        }}
      />
    </div>
  );
}
