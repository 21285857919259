import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, If } from '@/lib/v2/components';
import { ModalSingleInput } from '@/lib/v2/examples';

import {
  atomDisableEditAction,
  atomGlobalLoadingAction,
  atomProcessingAction,
} from '@/src/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomHeaderCurrentStep } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoSms } from '@/src/modules/CampaignsModule/atoms/actionSMSAtom';
import { atomToggleEditActionsModal } from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useActionData } from '@/src/modules/CampaignsModule/hooks/useActionData';
import { ISmsInfoResponseV1 } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { isActionEditable } from '@/src/modules/CampaignsModule/utils';

import { BackToCampaignsModal } from './components/BackToCampaignsModal';
import { HeaderActionButtons } from './components/HeaderActionButtons';

import { useEditActions } from '@/modules/CampaignsModule/hooks/useEditActions';

const HeaderSectionActions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId, actionId } = useParams();
  const { pathname } = useLocation();

  const [toggleBackToCampaignsModal, setToggleBackToCampaignsModal] = useState<boolean>(false);
  const [disableEditAction, setDisableEditAction] = useAtom(atomDisableEditAction);
  const globalLoadingAction = useAtomValue(atomGlobalLoadingAction);
  const processingAction = useAtomValue(atomProcessingAction);
  const currentStep = useAtomValue(atomHeaderCurrentStep);
  const setActionInfoSms = useSetAtom(atomActionInfoSms);

  const { action, refetch } = useActionData(Number(actionId));
  const { VALIDATION_RULES, handleApplyRename, handleCloseModal, handleValidation, loading } =
    useEditActions({ campaignId, actionId });
  const [toggleEditActionsModal, setToggleEditActionsModal] = useAtom(atomToggleEditActionsModal);

  useEffect(() => {
    //TODO: Review this refetch & atom for update data
    refetch();
    setDisableEditAction(!isActionEditable(Number(action?.status)));
  }, [action?.status, refetch, setDisableEditAction]);

  useEffect(() => {
    return () => setActionInfoSms({} as ISmsInfoResponseV1);
  }, [setActionInfoSms]);

  useEffect(() => {
    if (pathname.includes('/scheduling') || pathname.includes('/summary')) {
      refetch();
    }
  }, [pathname, refetch]);

  const mainActionPath = useMemo(() => {
    const parts = pathname.replace(/^\/|\/$/g, '').split('/');
    const mainPath = parts.slice(3, 5).join('/');
    return mainPath;
  }, [pathname]);

  const handleBackHeader = useCallback(() => {
    if (pathname.includes('/scheduling') || pathname.includes('/summary')) {
      navigate(-1);
    } else {
      !isActionEditable(Number(action?.status))
        ? navigate(`/v2/campaigns/${Number(campaignId)}`)
        : setToggleBackToCampaignsModal(true);
    }
  }, [action?.status, campaignId, navigate, pathname]);

  const handleOpenModal = useCallback(() => {
    setToggleEditActionsModal(true);
  }, [setToggleEditActionsModal]);

  /* cspell:disable*/
  const HeaderSectionPaths = useMemo(() => {
    const breadcrumbLinks = [
      {
        id: 'campaign-id-path',
        name: action ? action.campaignName : '',
        to: {
          path: action ? `/v2/campaigns/${action.campaignId}` : '',
        },
      },
      {
        id: 'action-name-path',
        name: action ? action.name : '',
        to: {
          path: mainActionPath,
        },
      },
    ];
    if (pathname.includes('/scheduling')) {
      breadcrumbLinks.push({
        id: 'scheduling-path',
        name: t('BREADCRUMB.sendConfiguration'),
        to: {
          path: `${mainActionPath}/scheduling`,
        },
      });
    }

    if (pathname.includes('/summary')) {
      breadcrumbLinks.push(
        {
          id: 'scheduling-path',
          name: t('BREADCRUMB.sendConfiguration'),
          to: {
            path: `${mainActionPath}/scheduling`,
          },
        },
        {
          id: 'summary-path',
          name: t('BREADCRUMB.summary'),
          to: {
            path: `${mainActionPath}/summary`,
          },
        }
      );
    }
    return breadcrumbLinks;
  }, [action, mainActionPath, pathname, t]);
  /* cspell:enable*/

  const HeaderSectionActionButtons = useMemo(
    () => (
      <HeaderActionButtons
        disabled={disableEditAction}
        loading={globalLoadingAction}
        processing={processingAction}
        step={currentStep}
      />
    ),
    [currentStep, disableEditAction, globalLoadingAction, processingAction]
  );

  return (
    <div className="relative z-[1] shadow-md">
      <If condition={!!action}>
        <Breadcrumb
          actionButtons={HeaderSectionActionButtons}
          customPaths={HeaderSectionPaths}
          handleOnBackClick={handleBackHeader}
          isEditable={!pathname.includes('scheduling') && !pathname.includes('summary')}
          itemId={action?.actionId}
          onEditableClick={handleOpenModal}
        />
      </If>

      <ModalSingleInput
        disabledUntilOnChange
        id="edit-action-table"
        isLoading={loading}
        isOpen={toggleEditActionsModal}
        rules={VALIDATION_RULES}
        title={t('CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS.title')}
        value={action?.name}
        onClose={handleCloseModal}
        onSubmit={handleApplyRename}
        onValidateInputFetch={handleValidation}
      />

      <BackToCampaignsModal
        isOpen={toggleBackToCampaignsModal}
        onClose={setToggleBackToCampaignsModal}
      />
    </div>
  );
};

export default HeaderSectionActions;
