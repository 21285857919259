import { EActionTypesPath } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

import { isActionEditable } from './isActionEditable';

interface ILocationAction {
  campaignId: number;
  actionId: number;
  featureFlag: {
    email: boolean;
    sms: boolean;
  };
  status: number;
  actionTypePath: string;
}

export const getLocationAction = ({
  campaignId,
  actionId,
  featureFlag,
  status,
  actionTypePath,
}: ILocationAction): string => {
  const baseActionPath = `/v2/campaigns/${campaignId}/${actionTypePath}/${actionId}`;
  const isEditableAction = !isActionEditable(Number(status));

  const shouldShowSummary = (flag: boolean, type: EActionTypesPath): boolean =>
    flag && isEditableAction && actionTypePath === type;

  if (
    shouldShowSummary(featureFlag.email, EActionTypesPath.EMAIL) ||
    shouldShowSummary(featureFlag.sms, EActionTypesPath.SMS)
  ) {
    return `${baseActionPath}/summary`;
  }

  return baseActionPath;
};
