import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import {
  atomActionType,
  atomFiltersAction,
  atomHistorySearchPagerActions,
  atomPagerAction,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomInfoCampaignV1 } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomCreateActionsModalIsOpen } from '@/modules/CampaignsModule/atoms/createActionsAtom';
import { defaultPager } from '@/modules/CampaignsModule/constants';
import { useActionTableUtils } from '@/modules/CampaignsModule/hooks/useActionTableUtils';
import { useFiltersActions } from '@/modules/CampaignsModule/hooks/useFiltersActions';
import {
  EChannelsID,
  ITableCampaignActions,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { ITablePager } from '@/modules/CampaignsModule/interfaces/Campaigns';
import { getLocationAction } from '@/modules/CampaignsModule/utils';

export const useHandleEvents = () => {
  const navigate = useNavigate();
  const createSimpleEmail = useFeatureFlag('createSimpleEmail');
  const createSimpleSMS = useFeatureFlag('createSimpleSMS');

  const filters = useAtomValue(atomFiltersAction);
  const pager = useAtomValue(atomPagerAction);
  const actionType = useAtomValue(atomActionType);

  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);
  const setPageParams = useSetAtom(atomPagerAction);

  const setHistorySearchPagerAction = useSetAtom(atomHistorySearchPagerActions);
  const setCreateActionsModalIsOpen = useSetAtom(atomCreateActionsModalIsOpen);

  const { resetFilters } = useFiltersActions();
  const {
    events: { getRoutePathAction },
  } = useActionTableUtils();

  const handleCreateActionByCampaign = useCallback(() => {
    setCreateActionsModalIsOpen(true);
  }, [setCreateActionsModalIsOpen]);

  const handleRowClick = useCallback(
    (row: ITableCampaignActions) => {
      const { name, channel, status, splitTest: isSplit, htmlOriginId, originId } = row.action;

      const router = getRoutePathAction({
        channel,
        htmlOriginId,
        isSplit,
        originId,
        createSimpleEmail,
      });

      setInfoCampaignV1({
        campaign: {
          id: Number(row.action.campaignId),
          name: row.action.campaignName ?? '',
          isAutomatic: channel === EChannelsID.MASTER,
        },
        action: {
          id: row.action.actionId,
          name: name,
        },
        route: actionType
          ? `/v2/campaigns/actions-${actionType}`
          : `/v2/campaigns/${row.action.campaignId}`,
      });

      setHistorySearchPagerAction({
        filters: { ...filters },
        pager: {
          page: pager?.page || defaultPager.page,
          limit: pager?.limit || defaultPager.limit,
        },
      });

      const locationAction = getLocationAction({
        campaignId: row.action.campaignId,
        actionId: row.action.actionId,
        status: status,
        featureFlag: {
          email: createSimpleEmail,
          sms: createSimpleSMS,
        },
        actionTypePath: router,
      });

      navigate(locationAction);
    },
    [
      createSimpleEmail,
      createSimpleSMS,
      actionType,
      filters,
      pager?.limit,
      pager?.page,
      navigate,
      getRoutePathAction,
      setHistorySearchPagerAction,
      setInfoCampaignV1,
    ]
  );

  const handleChangeTablePage = useCallback(
    ({ pageIndex, pageSize }: ITablePager) => {
      setPageParams({ page: ++pageIndex, limit: pageSize });
    },
    [setPageParams]
  );

  const handleClickEmptyScreenNoResult = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  return {
    handleChangeTablePage,
    handleCreateActionByCampaign,
    handleClickEmptyScreenNoResult,
    handleRowClick,
  };
};
