import { useAtom } from 'jotai';
import { ChangeEvent, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeleton } from '@/lib/components';
import { closeModal, FloatModal, openModal } from '@/lib/components/Modal/Modal';
import { Spinner } from '@/lib/components/Util/Spinner';
import { PlusIcon } from '@/lib/icon';
import { Button, Card, CardSection, Container, InputSearch, toast } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import ModalSelectable from '@/lib/v2/examples/ModalSelectable';
import { useShowModalSignal } from '@/lib/v2/hooks/useShowModalSignal';

import { useMutationUserContext, useStateUserContext } from '@/src/application/context/UserContext';
import { atomContactsDownload } from '@/src/atoms/Contacts';
import { DeleteModal } from '@/src/ContactsModule/components/ContactsModals';
import { ContactsToFlowModal } from '@/src/ContactsModule/components/ContactsModals/ContactsToFlowModal';
import { CreateAndEditGroupModal } from '@/src/ContactsModule/components/ContactsModals/CreateAndEditGroupModal';
import { GroupsActionBar } from '@/src/ContactsModule/components/GroupsActionBar/GroupsActionBar';
import { GroupsTable } from '@/src/ContactsModule/components/GroupsTable';
import { GroupIcon } from '@/src/ContactsModule/Icons/GroupIcon';
import { IGroup } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useGroupList } from '@/src/infrastructure/services/useGroupList';

import Title from './components/Title/Title';

import { DistributeModal, SectionHeader } from '@/modules/ContactsModule/components';
import {
  useMutationContactsContext,
  useStateContactsContext,
} from '@/modules/ContactsModule/contexts/ContactsContext';
import { useConnectContactsToFlows } from '@/modules/ContactsModule/hooks/useConnectContactsToFlows';
import { useDistributeContacts } from '@/modules/ContactsModule/hooks/useDistributeContacts';

export const GroupsMain = () => {
  const { t } = useTranslation();
  const { canCreateGroups, accountLimitsData, isLoadingAccountLimits } = useStateUserContext();
  const [initialLastRefreshValue] = useEmblue(ServiceMethods.getLastRefreshGroupsValue);
  const [contactsDownload, setContactsDownload] = useAtom(atomContactsDownload);

  const [lastRefresh, setLastRefresh] = useState<number>(initialLastRefreshValue ?? 0);
  const [isChecked, setIsChecked] = useState(false);
  const [orderColumn, setOrderColumn] = useState<string>();
  const [orderDirection, setOrderDirection] = useState<string>('asc');

  const { pageSize, pageIndex, totalPages, gotoPageIndex, searchQuery } = useStateContactsContext();
  const { setListTotalPagination, changeTablePage, setSearchQuery } = useMutationContactsContext();

  const [groupList, , isLoadingGroupsList] = useGroupList({
    identifier: 'GROUPS_VIEW',
    page: pageIndex,
    pageSize,
    search: searchQuery,
    orderColumn,
    orderDirection,
    lastRefresh,
  });
  const [groupsCountOfSearch, , isLoadingGroupsCountOfSearch] = useEmblue(
    ServiceMethods.getGroupsCountOfSearch,
    {
      search: searchQuery,
      lastRefresh,
    }
  );

  const [groupsCountOfTotal, , isLoadingGroupsCountTotal] = useEmblue(
    ServiceMethods.getGroupsCountOfSearch,
    {
      lastRefresh,
    }
  );

  const groupsCountSearch = groupsCountOfSearch?.count;
  const groupsCountTotal = groupsCountOfTotal?.count;

  const { toggleAccountLimits } = useMutationUserContext();

  const [flowsLists, , flowsLoading] = useEmblue(ServiceMethods.getFlowsByAccount);

  const [selectedGroupsRows, setSelectedGroupsRows] = useState<number[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [searchQueryHandler, setSearchQueryHandler] = useState(searchQuery);
  const service = useService();
  const [openContactsDownload, setOpenContactsDownload] = useState(false);
  const [wordingsDownload, setWordingsDownload] = useState({
    title: t('MODAL_CONTACTS_DOWNLOAD.title'),
    reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder'),
    resumeSelected: t('MODAL_CONTACTS_DOWNLOAD.resumeSelected'),
    search: {
      label: t('MODAL_CONTACTS_DOWNLOAD.search_label'),
      placeholder: t('MODAL_CONTACTS_DOWNLOAD.search_placeholder'),
    },
    actions: {
      accept: t('MODAL_CONTACTS_DOWNLOAD.export'),
    },
  });
  const {
    handleDistributeSubmit,
    distributeIsDisabled,
    distributeIsLoading,
    openDistributeModal,
    setOpenDistributeModal,
    quantityOptions,
    segmentsIds,
  } = useDistributeContacts({
    currentList: groupList?.list ?? [],
    selectedRows: selectedGroupsRows,
  });
  /** cspell:disable */
  const {
    flowsListsAux,
    openConnectToFlows,
    setOpenConnectToFlows,
    handleContactToFlow,
    showLoadingFlows,
  } = useConnectContactsToFlows({ type: 'usuario' });
  /** cspell:enable */

  const lastRefreshIncrement = useCallback(() => {
    setLastRefresh((prevState: number) => prevState + 1);
    service?.incrementLastRefreshGroupsValue();
  }, [service]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchQueryHandler(event.target.value);

  const handleClear = () => {
    setSearchQuery('');
    setSearchQueryHandler('');
  };

  const getOnSearch = () => {
    setSearchQuery(searchQueryHandler ?? '');
  };

  useEffect(() => {
    toggleAccountLimits('groups', groupsCountTotal ?? 0);
  }, [groupsCountTotal, toggleAccountLimits]);

  useEffect(() => {
    setSearchQueryHandler(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (initialLastRefreshValue && initialLastRefreshValue > lastRefresh) {
      setLastRefresh(initialLastRefreshValue);
    }
  }, [initialLastRefreshValue, lastRefresh]);

  useEffect(() => {
    setListTotalPagination(groupsCountSearch ? groupsCountSearch : 0);
  }, [setListTotalPagination, groupsCountSearch]);

  const changeOrderBy = useCallback((element: { id: string; isAsc: boolean | undefined }) => {
    const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
    setOrderDirection(orderDirectionValue);
    setOrderColumn(element.id);
  }, []);

  const createGroup = useCallback(() => {
    //TO DO: change this openModal to new Modal component v2
    openModal({
      element: <CreateAndEditGroupModal lastRefreshIncrement={lastRefreshIncrement} />,
    });
  }, [lastRefreshIncrement]);

  const resetGroups = () => {
    setSearchQuery('');
  };
  const [contactsFieldsResponse] = useEmblue(ServiceMethods.getFieldsFilters);

  const contactsFields = useMemo(() => {
    let items: any[] = [
      {
        id: 'email',
        label: 'Email',
        checked: true,
        disabled: true,
      },
    ];
    const data = contactsFieldsResponse?.data;

    if (!data || typeof data === 'string') return [];

    // Defaults fields
    const defaultFields = data?.defaultFields
      ?.filter((item) => item.name !== 'email')
      ?.map((item) => ({
        ...item,
        label: t(`CONTACT_INFORMATION.${item.name}`),
      }));
    items = items.concat(defaultFields ?? []);

    // Customs fields
    const customFields =
      data?.customFields
        ?.filter((item) => item?.metadata?.fieldType !== 'extended')
        ?.map((item) => ({ ...item, label: item.name })) ?? [];
    items = items.concat(customFields);

    // Activity fields
    const activityFields =
      data?.activityFields
        ?.filter((item) => item.name !== 'lastSend')
        ?.map((item) => {
          return {
            ...item,
            id: item.name,
            label: t(`CONTACT_INFORMATION.${item.name}`),
          };
        }) ?? [];
    items = items.concat(activityFields);

    return items;
  }, [contactsFieldsResponse?.data, t]);

  useEffect(() => {
    setIsChecked(selectedGroupsRows.length > 0);
  }, [selectedGroupsRows]);

  useEffect(() => {
    if (openDeleteModal) {
      getGroupsInUse().catch(console.error);
      setOpenDeleteModal(false);
    }
  }, [openDeleteModal]);

  useEffect(() => {
    if (openEditModal) {
      openEditModalFunction();
      setOpenEditModal(false);
    }
  }, [openEditModal]);

  useEffect(() => {
    if (openEditModal) {
      openEditModalFunction();
      setOpenEditModal(false);
    }
  }, [openEditModal]);

  useEffect(() => {
    if (confirmDelete) {
      deleteGroups()
        .then(() => lastRefreshIncrement())
        .catch(console.error);
      setConfirmDelete(false);
    }
  }, [confirmDelete]);

  const handleCloseModalDownload = useCallback(() => {
    setOpenContactsDownload(false);

    setTimeout(() => {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder'),
      }));
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        disabled: false,
      }));
    }, 500);
  }, [setContactsDownload, t]);

  const isAvailableFileExport = useCallback(() => {
    const dateStorage: string = localStorage.getItem('@segments/last_download') ?? '';
    const countDownload: string = localStorage.getItem('@segments/count_download') ?? '';
    const lastDownload = new Date(dateStorage);
    const currentTime = new Date();

    if (!dateStorage || parseInt(countDownload) < 5) return true;

    const diff = currentTime.getTime() - lastDownload.getTime();
    const hours = diff / (1000 * 60 * 60);

    if (hours >= 1) {
      localStorage.removeItem('@segments/last_download');
      localStorage.removeItem('@segments/count_download');

      return true;
    }

    return false;
  }, []);
  const handleExportContacts = useCallback(
    (fieldsForExport: any[]) => {
      if (!isAvailableFileExport()) return;

      void service?.exportGroupContacts(
        segmentsIds[0],
        fieldsForExport.filter((item) => item.id !== 'email')
      );
      setOpenContactsDownload(false);
      setContactsDownload((prevContactsDownload) => ({
        ...prevContactsDownload,
        show: true,
        file: {
          ...prevContactsDownload.file,
          name: 'segment',
        },
        status: 'loading',
        error: false,
        wordings: {
          message: t('NOTIFICATION_CONTACTS_DOWNLOAD.exporting'),
          action: '',
        },
      }));

      // Limit for 2 file download in 1h
      const countDownloadFile = localStorage.getItem('@segments/count_download');
      localStorage.setItem('@segments/last_download', new Date().toISOString());
      localStorage.setItem(
        '@segments/count_download',
        (countDownloadFile ? parseInt(countDownloadFile) + 1 : 1).toString()
      );
    },
    [isAvailableFileExport, segmentsIds, service, setContactsDownload, t]
  );

  const openEditModalFunction = () => {
    const selectedGroupRow = selectedGroupsRows.at(0);
    if (groupList === undefined || selectedGroupRow === undefined) {
      return;
    }
    const selectedGroup = groupList.list.at(selectedGroupRow)?.group;
    if (selectedGroup === undefined) {
      return;
    }
    //TO DO: change this openModal to new Modal component v2
    openModal({
      element: (
        <CreateAndEditGroupModal
          editProps={{ groupId: selectedGroup.id, groupName: selectedGroup.groupName }}
          lastRefreshIncrement={lastRefreshIncrement}
        />
      ),
    });
  };

  const getGroupsSelected = useCallback(() => {
    if (groupList === undefined || selectedGroupsRows.length < 1) return [];
    const groupsIds: IGroup[] =
      selectedGroupsRows.length === pageSize
        ? groupList.list.map((g) => g.group) // all ids of page
        : groupList.list.filter((c, i) => selectedGroupsRows.includes(i)).map((g) => g.group); // ids of some contacts in page

    return groupsIds;
  }, [groupList, pageSize, selectedGroupsRows]);

  const getGroupsInUse = async () => {
    const groupsIds = getGroupsSelected()?.map((item) => item.id);

    if (groupsIds === undefined || groupsIds.length < 1) return;
    const groupsInUse = await service?.getGroupsInUse({ groupsIds: groupsIds });
    const modalTitle =
      selectedGroupsRows.length === 1
        ? t('GROUP_DELETE_MODAL.title')
        : t('GROUP_DELETE_MODAL.titlePlural');
    if (groupsInUse !== undefined && groupsInUse.length > 0) {
      let modalDescriptionJsx: JSX.Element;
      // eslint-disable-next-line prefer-const
      modalDescriptionJsx = (
        <p>
          {' '}
          {t('GROUP_DELETE_MODAL.descriptionPlural')} <br /> {t('GROUP_DELETE_MODAL.groupsInUse')}
          <ul className="list-inside list-disc">
            {groupsInUse.map((g) => (
              <li key={g.groupId}>{g.name}</li>
            ))}
          </ul>
        </p>
      );
      //TO DO: change this openModal to new Modal component v2
      openModal({
        element: (
          <DeleteModal
            JSXElementDescription={modalDescriptionJsx}
            description={''}
            setConfirmDelete={setConfirmDelete}
            title={modalTitle}
          />
        ),
      });
    } else {
      const modalDescription =
        selectedGroupsRows.length === 1
          ? t('GROUP_DELETE_MODAL.description')
          : t('GROUP_DELETE_MODAL.descriptionPlural');
      //TO DO: change this openModal to new Modal component v2
      openModal({
        element: (
          <DeleteModal
            description={modalDescription}
            setConfirmDelete={setConfirmDelete}
            title={modalTitle}
          />
        ),
      });
    }
  };

  const deleteGroups = async () => {
    if (groupList === undefined || selectedGroupsRows.length < 1) return;
    //TO DO: change this openModal to new Modal component v2
    openModal({
      element: (
        <FloatModal>
          <Spinner />
        </FloatModal>
      ),
    });
    const groupsIds: number[] =
      selectedGroupsRows.length === pageSize
        ? groupList.list.map((g) => g.group.id) // all ids of page
        : groupList.list.filter((c, i) => selectedGroupsRows.includes(i)).map((g) => g.group.id); // ids of some contacts in page
    const result = await service?.deleteSelectedGroups({ groupsIds: groupsIds });
    closeModal(); // Spinner
    showNotification(result ?? false);
  };

  const showNotification = (result: boolean) => {
    toast({
      title: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
      body: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
      variant: result ? 'success' : 'error',
    });
  };

  const handleConfirmGroupsToFlow = useCallback(
    async (flowId: number) => {
      const groups = getGroupsSelected()?.map((item: IGroup) => item?.id);

      if (!groups) return;

      const result = await handleContactToFlow(flowId, groups);

      if (result) {
        setSelectedGroupsRows([]);
        lastRefreshIncrement();
      }
    },
    [getGroupsSelected, handleContactToFlow, lastRefreshIncrement]
  );

  const handleOpenModalDownload = useCallback(() => {
    if (!isAvailableFileExport()) {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.limitExceeded'),
      }));
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        disabled: true,
      }));
    }

    setOpenContactsDownload(true);
  }, [isAvailableFileExport, setContactsDownload, t]);

  useShowModalSignal('CreateGroup', createGroup);

  return (
    <>
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              actionsButtons={
                <Button
                  disabled={!canCreateGroups}
                  iconLeft={<PlusIcon />}
                  id="create-group-button"
                  onClick={createGroup}
                >
                  {t('CONTACTS_ACTIONS_DROPDOWN.Group')}
                </Button>
              }
              icon={<GroupIcon />}
              justifyContentActionsButtons="end"
              title={
                <Title
                  groupListLength={groupList?.list.length}
                  groupsCountSearch={groupsCountSearch}
                  groupsCountTotal={groupsCountTotal}
                  groupsLimit={accountLimitsData?.groupsLimit}
                  isLoadingAccountLimits={isLoadingAccountLimits}
                  isLoadingGroupsCountOfSearch={isLoadingGroupsCountOfSearch}
                  isLoadingGroupsCountTotal={isLoadingGroupsCountTotal}
                  isLoadingGroupsList={isLoadingGroupsList}
                  searchQuery={searchQuery}
                />
              }
            />
            <Flex column withGap className="mt-7" gapSize="medium">
              {!isChecked ? (
                <Flex
                  alignment="start"
                  className={`mb-[-62px] ${
                    !isChecked ? '!w-[340px] xl:max-w-[900px] 2xl:max-w-fit' : 'w-full'
                  } self-start`}
                >
                  <Container background="white">
                    {(groupsCountOfTotal ? groupsCountOfTotal?.count : 0) > 0 && (
                      <div className="w-[340px]">
                        <InputSearch
                          id={'search-groups'}
                          placeHolder={t('GROUPS_FILTERS.searchInputBar')}
                          value={searchQueryHandler}
                          onAction={getOnSearch}
                          onChange={handleOnChange}
                          onClear={handleClear}
                        />
                      </div>
                    )}
                  </Container>
                </Flex>
              ) : (
                <Flex alignment="start" className="z-50 mb-[-62px] self-start">
                  <GroupsActionBar
                    contactSelection={selectedGroupsRows.length}
                    distributeIsDisabled={distributeIsDisabled}
                    groupsSelected={getGroupsSelected()}
                    setOpenContactsDownload={handleOpenModalDownload}
                    setOpenContactsToFlow={setOpenConnectToFlows}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setOpenDistributeContacts={setOpenDistributeModal}
                    setOpenEditModal={setOpenEditModal}
                  />
                </Flex>
              )}
              {!groupList && (
                <div className="size-full py-10">
                  <TableSkeleton />
                </div>
              )}
              {groupList && (
                <GroupsTable
                  callToAction={[resetGroups, createGroup]}
                  changeTableOrder={changeOrderBy}
                  changeTablePage={changeTablePage}
                  gotoPageIndex={gotoPageIndex}
                  groupList={groupList.list}
                  isLoading={isLoadingGroupsList}
                  setSelectedGroups={setSelectedGroupsRows}
                  totalGroupCount={groupsCountTotal ?? 0}
                  totalPages={totalPages}
                />
              )}
            </Flex>
          </Flex>
        </CardSection>
      </Card>
      <DistributeModal
        isLoading={distributeIsLoading}
        isOpen={openDistributeModal}
        quantityOptions={quantityOptions}
        onClose={setOpenDistributeModal}
        onSubmit={handleDistributeSubmit}
      />
      {openConnectToFlows && !flowsLoading && (
        <ContactsToFlowModal
          itemList={
            flowsLists && typeof flowsLists?.list === 'string' ? flowsLists.list : flowsListsAux
          }
          loading={showLoadingFlows}
          setConfirmToFlow={handleConfirmGroupsToFlow}
          wordings={{
            title: 'CONTACT_GROUPS_FLOW_MODAL.title',
            description: 'CONTACT_GROUPS_FLOW_MODAL.description',
          }}
          onClose={() => setOpenConnectToFlows(false)}
        />
      )}

      {openConnectToFlows && flowsLoading && (
        <FloatModal>
          <Spinner />
        </FloatModal>
      )}
      <Suspense>
        <ModalSelectable
          isAcceptDisabled={contactsDownload.disabled}
          items={contactsFields}
          show={openContactsDownload}
          wordings={wordingsDownload}
          onAccept={(e, selected) => handleExportContacts(selected)}
          onClose={handleCloseModalDownload}
        />
      </Suspense>
    </>
  );
};
