/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { APIService } from '@/src/infrastructure/Protocol/APIService';

import {
  IEmblueApiMethodNameMap,
  IInboxEvent,
  IInboxEventsPayload,
  IInboxEventsResponse,
  IInboxService,
} from './IInboxService';

export class InboxService implements IInboxService {
  private api: APIService;
  private pathInboxEvents = 'api/v2.1/contacts';

  private constructor(api: APIService) {
    this.api = api;
  }

  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error InboxService[${method}]:`, message);
  }

  static create(api: APIService): IInboxService {
    return new InboxService(api);
  }

  async getEventsInbox(query: IInboxEventsPayload): Promise<IInboxEventsResponse> {
    try {
      const { data } = await this.api.post<IInboxEvent[]>(
        `${this.pathInboxEvents}/search_inbox_events`,
        query
      );

      const events: IInboxEvent[] = data.map((event) => ({
        id: event.id,
        contactId: event.contactId,
        conversationId: event.conversationId,
        agentId: event.agentId,
        agentName: event.agentName,
        status: event.status,
        channel: event.channel,
        description: event.description,
        createdAt: event.createdAt,
        lastUpdatedAt: event.lastUpdatedAt,
      }));

      return {
        events,
        total: events.length,
        partial: events.length,
      };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getEventsInbox');
      return { events: [], total: 0, partial: 0 };
    }
  }
}

export const InboxServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  InboxService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof InboxService);
