import classNames from 'classnames';
import { useAtom } from 'jotai';
import {
  memo,
  MouseEventHandler,
  ReactNode,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ArrowBackIcon } from '@/lib/icon';
import { Button, Container, IconSvg } from '@/lib/v2/components';
import ModalSelectable from '@/lib/v2/examples/ModalSelectable';
import { DownloadIcon } from '@/lib/v2/icons/general/DownloadIcon';
import { JustifyContentBase } from '@/lib/v2/types';

import { atomContactsDownload } from '@/src/atoms/Contacts';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';

interface SectionHeaderProps {
  icon?: ReactNode;
  title?: ReactNode;
  withoutDivide?: boolean;
  actionsButtons?: ReactNode;
  backButton?: boolean;
  onBack?: MouseEventHandler<HTMLDivElement>;
  justifyContentActionsButtons?: JustifyContentBase;
  children?: ReactNode;
  isLoading?: boolean;
}

const SectionHeader = ({
  icon,
  title,
  withoutDivide = false,
  actionsButtons,
  backButton,
  onBack,
  justifyContentActionsButtons = 'start',
  children,
  isLoading,
}: SectionHeaderProps) => {
  const classes = classNames('flex flex-row gap-3 items-center py-4');
  const { t } = useTranslation();
  const service = useService();
  const classesActionsButtons = classNames('flex flex-1', {
    [`justify-${justifyContentActionsButtons}`]: justifyContentActionsButtons,
  });
  const { groupId } = useParams();
  const [openContactsDownload, setOpenContactsDownload] = useState(false);
  const [contactsDownload, setContactsDownload] = useAtom(atomContactsDownload);
  const [wordingsDownload, setWordingsDownload] = useState({
    title: t('MODAL_CONTACTS_DOWNLOAD.title'),
    reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder'),
    resumeSelected: t('MODAL_CONTACTS_DOWNLOAD.resumeSelected'),
    search: {
      label: t('MODAL_CONTACTS_DOWNLOAD.search_label'),
      placeholder: t('MODAL_CONTACTS_DOWNLOAD.search_placeholder'),
    },
    actions: {
      accept: t('MODAL_CONTACTS_DOWNLOAD.export'),
    },
  });

  const handleCloseModalDownload = useCallback(() => {
    setOpenContactsDownload(false);

    setTimeout(() => {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder'),
      }));
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        disabled: false,
      }));
    }, 500);
  }, [setContactsDownload, t]);
  const [contactsFieldsResponse] = useEmblue(ServiceMethods.getFieldsFilters);

  const contactsFields = useMemo(() => {
    let items: any[] = [
      {
        id: 'email',
        label: 'Email',
        checked: true,
        disabled: true,
      },
    ];
    const data = contactsFieldsResponse?.data;

    if (!data || typeof data === 'string') return [];

    // Defaults fields
    const defaultFields = data?.defaultFields
      ?.filter((item) => item.name !== 'email')
      ?.map((item) => ({
        ...item,
        label: t(`CONTACT_INFORMATION.${item.name}`),
      }));
    items = items.concat(defaultFields ?? []);

    // Customs fields
    const customFields =
      data?.customFields
        ?.filter((item) => item?.metadata?.fieldType !== 'extended')
        ?.map((item) => ({ ...item, label: item.name })) ?? [];
    items = items.concat(customFields);

    // Activity fields
    const activityFields =
      data?.activityFields
        ?.filter((item) => item.name !== 'lastSend')
        ?.map((item) => {
          return {
            ...item,
            id: item.name,
            label: t(`CONTACT_INFORMATION.${item.name}`),
          };
        }) ?? [];
    items = items.concat(activityFields);

    return items;
  }, [contactsFieldsResponse?.data, t]);

  const isAvailableFileExport = useCallback(() => {
    const dateStorage: string = localStorage.getItem('@segments/last_download') ?? '';
    const countDownload: string = localStorage.getItem('@segments/count_download') ?? '';
    const lastDownload = new Date(dateStorage);
    const currentTime = new Date();

    if (!dateStorage || parseInt(countDownload) < 5) return true;

    const diff = currentTime.getTime() - lastDownload.getTime();
    const hours = diff / (1000 * 60 * 60);

    if (hours >= 1) {
      localStorage.removeItem('@segments/last_download');
      localStorage.removeItem('@segments/count_download');

      return true;
    }

    return false;
  }, []);
  const handleExportContacts = useCallback(
    (fieldsForExport: any[]) => {
      if (!isAvailableFileExport() || !groupId) return;

      void service?.exportGroupContacts(
        parseInt(groupId),
        fieldsForExport.filter((item) => item.id !== 'email')
      );
      setOpenContactsDownload(false);
      setContactsDownload((prevContactsDownload) => ({
        ...prevContactsDownload,
        show: true,
        file: {
          ...prevContactsDownload.file,
          name: 'group',
        },
        status: 'loading',
        error: false,
        wordings: {
          message: t('NOTIFICATION_CONTACTS_DOWNLOAD.exporting'),
          action: '',
        },
      }));

      // Limit for 2 file download in 1h
      const countDownloadFile = localStorage.getItem('@segments/count_download');
      localStorage.setItem('@segments/last_download', new Date().toISOString());
      localStorage.setItem(
        '@segments/count_download',
        (countDownloadFile ? parseInt(countDownloadFile) + 1 : 1).toString()
      );
    },
    [isAvailableFileExport, groupId, service, setContactsDownload, t]
  );

  const handleOpenModalDownload = useCallback(() => {
    if (!isAvailableFileExport()) {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.limitExceeded'),
      }));
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        disabled: true,
      }));
    }

    setOpenContactsDownload(true);
  }, [isAvailableFileExport, setContactsDownload, t]);

  return (
    <>
      <Container>
        <div className={classes}>
          {isLoading && (
            <div className="flex w-full animate-pulse flex-col">
              <div className="my-2 h-5 w-1/4 rounded-full bg-gray-200"></div>
            </div>
          )}
          {!isLoading && (
            <>
              <div className="flex flex-row items-center gap-3">
                {backButton && (
                  <IconSvg
                    height="22px"
                    svgComponent={<ArrowBackIcon />}
                    width="22px"
                    onClick={onBack}
                  />
                )}
                <div className="h-full">{icon}</div>
                {title}
              </div>
              <div className={classesActionsButtons}>{actionsButtons}</div>
              {children}
            </>
          )}
          <Button
            outline
            standard
            disabled={contactsDownload.show}
            iconLeft={
              <IconSvg
                fillColor="secondary"
                height="20px"
                svgComponent={<DownloadIcon color="#004DBC" />}
                width="20px"
              />
            }
            id="download-button"
            secondary={true}
            tooltip={t('SEGMENT_DOWNLOAD_MODAL.title')}
            onClick={handleOpenModalDownload}
          >
            {t('SEGMENT_FILTERS.download')}
          </Button>
        </div>
      </Container>
      {!withoutDivide && <div className="h-[1px] w-full bg-[#e5e7eb]"></div>}

      <Suspense>
        <ModalSelectable
          isAcceptDisabled={contactsDownload.disabled}
          items={contactsFields}
          show={openContactsDownload}
          wordings={wordingsDownload}
          onAccept={(e, selected) => handleExportContacts(selected)}
          onClose={handleCloseModalDownload}
        />
      </Suspense>
    </>
  );
};

export default memo(SectionHeader);
