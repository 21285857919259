import { timeout } from '@/src/compat/util';

import {
  IInboxEvent,
  IInboxEventsPayload,
  IInboxEventsResponse,
  IInboxService,
} from './IInboxService';

export class StubInboxService implements IInboxService {
  static create(): IInboxService {
    return new StubInboxService();
  }

  async getEventsInbox(query: IInboxEventsPayload): Promise<IInboxEventsResponse> {
    try {
      await timeout(2000);

      const mockEvents: IInboxEvent[] = [
        {
          id: 2,
          contactId: 38,
          conversationId: 1,
          agentId: 101,
          agentName: 'John Doe',
          status: 'pending',
          channel: 'whatsapp',
          description: 'New customer inquiry about product features',
        },
        {
          id: 1,
          contactId: 38,
          conversationId: 67890,
          agentId: 101,
          agentName: 'John Doe',
          status: 'pending',
          channel: 'whatsapp',
          description: 'New customer inquiry about product features',
        },
      ];

      return {
        events: mockEvents,
        total: mockEvents.length,
        partial: mockEvents.length,
      };
    } catch (error) {
      console.error('Error in StubInboxService.getEventsInbox:', error);
      return { events: [], total: 0, partial: 0 };
    }
  }
}
