import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Cell,
  CellProps,
  Column,
  HeaderGroup,
  HeaderProps,
  Hooks,
  Row,
  TableInstance,
  TableState,
  TableToggleCommonProps,
  useExpanded,
  UseExpandedRowProps,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useRowSelect,
  useTable,
} from 'react-table';

import { Pagination } from '@/lib/components/Pagination';
import { Select } from '@/lib/components/Select';
import { ItemSelectProps } from '@/lib/components/Select/Select';
import { BigColumnSkeleton } from '@/lib/components/Skeletons/ColumnSkeleton';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { IndeterminateCheckbox } from './IndeterminateCheckbox';

export type TableProps<T> = {
  columns: Column<object>[];
  data: T[] | number[] | undefined;
  onRowClick?: (obj: T) => void;
  withCheckbox?: boolean;
  withSubRows?: boolean;
  expandSubRowsOnClick?: 'row' | 'icon';
  rowIndexWithoutCheckbox?: number[];
  setSelectedRows?: Dispatch<SetStateAction<number[]>>;
  withScrollbar?: boolean;
  noNegativeMargin?: boolean;
  classNamePagination?: string;
  withPagination?: {
    totalPages?: number;
    fetchData: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  };
  orderBy?: {
    items: ItemSelectProps[];
    onSelect: (element: ItemSelectProps, current: string) => void;
  };
  children?: ReactNode | ReactNode;
  isLoading?: boolean;
  gotoPageIndex?: number;
  defaultSize?: number;
  className?: string;
  emptyScreen?: {
    totalCount: number;
    isEmptySegment?: boolean;
    noResult: ReactNode | ReactNode;
    noData: ReactNode | ReactNode;
    noContactSegment?: ReactNode | ReactNode;
    noPerms?: ReactNode | ReactNode;
    perms?: string | undefined;
  };
  toggleAllRowsSelectedValue?: boolean; // false => unselect all items. true => select all items
  isPaginateOnOverflow?: boolean;
  overflow?: 'auto' | 'hidden' | 'visible' | 'scroll' | 'overflow-x-auto' | 'overflow-y-auto';
  paginationWithPadding?: boolean;
  lastPageViewed?: number;
  emptyScreenOnly?: boolean;
  stickyHeader?: boolean;
};

interface SelectionRow<D extends object = {}> extends Row<D> {
  getToggleRowSelectedProps: () => TableToggleCommonProps;
}

interface ExpandedRow extends Row<object>, UseExpandedRowProps<object> {}

export function Table<T>({
  columns,
  data,
  onRowClick,
  className,
  withCheckbox,
  withSubRows = false,
  rowIndexWithoutCheckbox,
  setSelectedRows,
  withPagination,
  withScrollbar,
  orderBy,
  children,
  isLoading,
  gotoPageIndex,
  defaultSize,
  emptyScreenOnly = true,
  emptyScreen,
  isPaginateOnOverflow,
  toggleAllRowsSelectedValue = false,
  overflow = 'visible',
  noNegativeMargin = false,
  classNamePagination,
  paginationWithPadding,
  lastPageViewed,
  expandSubRowsOnClick = 'icon',
  stickyHeader = false,
}: TableProps<T>) {
  const { t } = useTranslation();
  const useCheckboxes = (hooks: Hooks) => {
    withCheckbox &&
      hooks.visibleColumns.push((columnList: Column[]) => [
        {
          id: 'selection',
          width: '20px',
          className: 'checkbox',
          // eslint-disable-next-line @typescript-eslint/ban-types
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<{}>) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: (props: CellProps<{}>) => {
            const row: SelectionRow = props.row as SelectionRow;
            return (
              <div>
                {!rowIndexWithoutCheckbox?.includes(row.index) && (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                )}
              </div>
            );
          },
        },
        ...columnList,
      ]);
  };

  const useCollapse = (hooks: Hooks) => {
    withSubRows &&
      hooks.visibleColumns.push((columnList: Column[]) => [
        {
          id: 'expander',
          Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span
              {...getToggleAllRowsExpandedProps({
                style: {
                  display: 'block',
                  width: 'auto',
                },
              })}
            >
              {isAllRowsExpanded ? (
                <ChevronUpIcon className="size-5 fill-emblue-gray" />
              ) : (
                <ChevronDownIcon className="size-5 fill-emblue-gray" />
              )}
            </span>
          ),
          Cell: ({ row }) => {
            const expandedRow = row as ExpandedRow;
            return expandedRow.canExpand ? (
              <span
                {...(expandSubRowsOnClick === 'icon' && {
                  ...expandedRow.getToggleRowExpandedProps({
                    style: {
                      display: 'block',
                      width: 'auto',
                    },
                  }),
                })}
              >
                {expandedRow.isExpanded ? (
                  <ChevronUpIcon className="size-5 fill-emblue-gray" />
                ) : (
                  <ChevronDownIcon className="size-5 fill-emblue-gray" />
                )}
              </span>
            ) : null;
          },
        },
        ...columnList,
      ]);
  };

  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
    toggleAllRowsSelected,
    getTableProps,
    getTableBodyProps,
  } = useTable(
    {
      columns: columns || [],
      data: (data || []) as unknown as readonly object[],
      manualPagination: true,
      pageCount: withPagination?.totalPages,
      initialState: {
        pageIndex: lastPageViewed ? lastPageViewed - 1 : 0,
        pageSize: defaultSize ?? 10,
      } as Partial<UsePaginationState<object>> & Partial<TableState<object>>,
    },
    useExpanded,
    usePagination,
    useRowSelect,
    useCheckboxes,
    useCollapse
  ) as TableInstance<object> & UsePaginationInstanceProps<object>;

  const stickyRef = useRef<HTMLTableSectionElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    withPagination?.fetchData && withPagination.fetchData({ pageIndex, pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withPagination?.fetchData, pageIndex, pageSize]);

  useEffect(() => {
    if (!gotoPageIndex) {
      gotoPage(gotoPageIndex ? gotoPageIndex : 0);
    }
  }, [withPagination?.fetchData, gotoPageIndex, gotoPage]);

  const AddExtraRow = withPagination || orderBy || children;

  useEffect(() => {
    if (setSelectedRows) setSelectedRows(Object.keys(selectedRowIds).map((i) => Number(i)));
  }, [selectedRowIds, setSelectedRows]);

  useEffect(() => {
    toggleAllRowsSelected(toggleAllRowsSelectedValue);
  }, [toggleAllRowsSelected, toggleAllRowsSelectedValue]);

  useEffect(() => {
    const cachedRef = stickyRef.current;
    if (!cachedRef) return;

    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.boundingClientRect.top <= 56),
      {
        threshold: [1],
      }
    );

    observer.observe(cachedRef);

    const handleScroll = () => {
      const stickyTop = cachedRef.getBoundingClientRect().top;
      setIsSticky(stickyTop <= 56);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      observer.unobserve(cachedRef);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const validationEmptyScreen = () => {
    const hasNoData =
      data &&
      data.length === 0 &&
      emptyScreen &&
      emptyScreen.totalCount === 0 &&
      !emptyScreen.isEmptySegment &&
      !emptyScreen.perms;
    const hasNoContactSegmentOrPerms =
      (emptyScreen && emptyScreen.isEmptySegment) || (emptyScreen && emptyScreen.perms);
    const hasDataAndNotPerms = data && data.length > 0 && !emptyScreen?.perms;

    let emptyScreenMessage = emptyScreen && emptyScreen.noResult;

    if (hasNoData) {
      emptyScreenMessage = emptyScreen.noData;
    } else if (hasNoContactSegmentOrPerms) {
      emptyScreenMessage = emptyScreen.noContactSegment || emptyScreen.noPerms;
    } else if (hasDataAndNotPerms) {
      return null;
    }

    return (
      <Flex alignment="center" itemAlignment="center">
        {emptyScreenMessage}
      </Flex>
    );
  };

  const headerClassWrapper = classNames({
    'sticky top-14 z-[1]': stickyHeader,
    shadow: isSticky,
  });

  const headerRowClass = classNames(
    'bg-emblueLightGray text-14 font-medium text-gray-700 lg:px-2 xl:px-2 2xl:px-4 transition-all',
    {
      'py-4': !(stickyHeader && isSticky),
      'py-3': stickyHeader && isSticky,
    }
  );

  const renderEmpty = validationEmptyScreen();
  if (emptyScreenOnly && renderEmpty) return renderEmpty;

  return (
    <div
      className={`${!noNegativeMargin ? '-mx-4 -mt-4' : ''} ${overflow && overflow} ${
        withScrollbar ? 'visible-scrollbar max-h-[650px]' : ''
      } ${className}`}
    >
      <>
        {AddExtraRow && (
          <Flex>
            <div className="pl-1">
              {(children || orderBy) && (
                <Flex noGrow withGap gapSize="small">
                  {children && children}
                  {orderBy && (
                    <Flex withGap>
                      <p className="whitespace-nowrap">{t('CONTACTS_TABLE.orderBy')} </p>
                      <Select plain itemList={orderBy.items} onSelect={orderBy.onSelect} />
                    </Flex>
                  )}
                </Flex>
              )}
            </div>
            <div className={paginationWithPadding ? 'px-3 lg:px-4 2xl:px-12' : classNamePagination}>
              {withPagination && (
                <Pagination
                  functions={{
                    canPreviousPage,
                    previousPage,
                    canNextPage,
                    nextPage,
                    pageCount,
                    pageIndex,
                    pageOptions,
                    pageSize,
                    setPageSize,
                  }}
                />
              )}
            </div>
          </Flex>
        )}

        {isLoading && (
          <Flex>
            {columns.map((_, i) => (
              <BigColumnSkeleton key={i?.toString()} />
            ))}
          </Flex>
        )}
        {!isLoading && (
          <table className="min-w-full table-auto divide-y divide-gray-300" {...getTableProps()}>
            <thead ref={stickyRef} className={headerClassWrapper}>
              {headerGroups.map((headerGroup: HeaderGroup<object>) => {
                const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                  <tr key={key} {...restHeaderGroupProps}>
                    {headerGroup.headers.map((column: HeaderGroup<object>) => {
                      const { key, ...restHeaderProps } = column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth,
                          width: column.width,
                          maxWidth: column.maxWidth,
                        },
                      });
                      return (
                        <th key={key} className={headerRowClass} {...restHeaderProps}>
                          {column.render('Header')}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
              {page.map((row) => {
                const expandedRow = row as ExpandedRow;
                prepareRow(expandedRow);
                const { key, ...restRowProps } = row.getRowProps();

                return (
                  <tr
                    key={key}
                    className={classNames('active:bg-slate-100', {
                      'cursor-pointer hover:bg-slate-50':
                        onRowClick || (expandSubRowsOnClick === 'row' && expandedRow.canExpand),
                      '!bg-emblueLightGray': expandedRow.depth > 0,
                    })}
                    {...(expandSubRowsOnClick === 'row' && {
                      onClick: () => expandedRow.toggleRowExpanded(),
                    })}
                    {...restRowProps}
                  >
                    {row.cells.map((cell: Cell<object, unknown>) => {
                      const { key, ...restCellProps } = cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                          maxWidth: cell.column.maxWidth,
                        },
                      });
                      return (
                        <td
                          key={key}
                          className="text-sm py-4 text-gray-900 lg:px-2 xl:px-2 2xl:px-4"
                          role="gridcell"
                          onClick={() =>
                            onRowClick && cell.column.id !== 'selection'
                              ? onRowClick(row.original as T)
                              : noop
                          }
                          {...restCellProps}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {!isLoading && renderEmpty}
      </>
      {data && ((data.length >= 10 && !isPaginateOnOverflow) || data.length >= 11) && (
        <div className="mt-2 w-full bg-slate-50 pb-12 pt-4">
          <div className="min-w-full">
            <Flex alignment="end">
              <div
                className={paginationWithPadding ? 'px-3 lg:px-4 2xl:px-12' : classNamePagination}
              >
                {withPagination && (
                  <Pagination
                    resetScroll
                    functions={{
                      canPreviousPage,
                      previousPage,
                      canNextPage,
                      nextPage,
                      pageCount,
                      pageIndex,
                      pageOptions,
                      pageSize,
                      setPageSize,
                    }}
                  />
                )}
              </div>
            </Flex>
          </div>
        </div>
      )}
    </div>
  );
}
